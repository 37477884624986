var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import { locationsUtils } from 'conversional-utils';
var initialState = {
    loading: false,
    error: null,
    locations: [],
    selectedLocation: null,
    locationMeta: null
};
var locationsSlice = createSlice({
    name: 'locations',
    initialState: initialState,
    reducers: {
        selectLocation: function (state, action) {
            state.selectedLocation = action.payload;
        },
        getLocations: {
            reducer: function (state, action) {
                state.loading = true;
                state.error = null;
            },
            prepare: function (closeTo, locationType, max_result, extraPayload) {
                return {
                    payload: __assign({ closeTo: closeTo, locationType: locationType, max_result: max_result }, (extraPayload || {})),
                };
            },
        },
        getLocationsSucceeded: function (state, action) {
            state.loading = false;
            state.locations = locationsUtils.sortLocations(action.payload);
        },
        getLocationsFailed: function (state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        getLocationMeta: {
            reducer: function (state, action) {
                state.loading = true;
                state.error = null;
            },
            prepare: function (action) {
                return {
                    payload: {
                        locationId: action.locationId,
                        key: action.key
                    },
                };
            }
        },
        getLocationMetaSucceeded: function (state, action) {
            state.loading = false;
            state.locationMeta = action.payload.meta;
        },
        getLocationMetaFailed: function (state, action) {
            state.loading = false;
            state.error = action.payload;
        }
    },
});
export var getLocations = (_a = locationsSlice.actions, _a.getLocations), getLocationsSucceeded = _a.getLocationsSucceeded, getLocationsFailed = _a.getLocationsFailed, selectLocation = _a.selectLocation, getLocationMeta = _a.getLocationMeta, getLocationMetaFailed = _a.getLocationMetaFailed, getLocationMetaSucceeded = _a.getLocationMetaSucceeded;
export default locationsSlice.reducer;
