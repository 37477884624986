import { useEffect } from 'react';
export var useScript = function (url, callback) {
    useEffect(function () {
        var script = document.createElement('script');
        script.src = url;
        script.async = true;
        script.onload = function () {
            if (callback)
                callback();
        };
        document.body.appendChild(script);
        return function () {
            document.body.removeChild(script);
        };
    }, [url]);
};
