import { createSelector } from '@reduxjs/toolkit';
var selectTimeslots = function (state) { return state.timeslots; };
export var selectTimeslotsList = createSelector(selectTimeslots, function (timeslots) { return timeslots.timeslots; });
export var selectReschedulingTimeslotList = createSelector(selectTimeslots, function (timeslots) { return timeslots.reschedulingTimeslots; });
export var selectTimeslotsLoading = createSelector(selectTimeslots, function (timeslots) { return timeslots.loading; });
export var selectTimeslotsError = createSelector(selectTimeslots, function (timeslots) { return timeslots.error; });
export var selectProperTimeslotList = createSelector([selectTimeslots, function (state, args) { return args; }], function (timeslots, _a) {
    var serviceRequestToken = _a.serviceRequestToken;
    return serviceRequestToken ? timeslots.reschedulingTimeslots : timeslots.timeslots;
});
