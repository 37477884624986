var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import merge from 'lodash/merge';
import get from "lodash/get";
import { MESSAGETYPES, sendToHost } from 'conversional-iframe-communication';
import { setItem } from 'conversional-persistent-storage';
var initialState = {
    sharedStore: {
        entryOpenCount: 0,
        resultsOpenCount: 0,
    },
    contextComponents: {},
    lastCta: {
        cta: '',
        label: '',
    },
    formSubmitted: {}
};
var commonSlice = createSlice({
    name: 'common',
    initialState: initialState,
    reducers: {
        broadcastSharedStoreChange: {
            reducer: function (state, action) {
                var selectedSharedStore = state.sharedStore;
                var _a = action.payload, journeyIsOpen = _a.journeyIsOpen, resultIsOpen = _a.resultIsOpen;
                var sharedStore = __assign({}, selectedSharedStore);
                if (journeyIsOpen) {
                    sharedStore.entryOpenCount++;
                }
                if (resultIsOpen) {
                    sharedStore.resultsOpenCount++;
                }
                var payload = __assign(__assign({}, sharedStore), action.payload);
                sendToHost.publish(MESSAGETYPES.SET_SHARED_STORE, payload);
            },
            prepare: function (data) {
                return { payload: data };
            },
        },
        setSharedStore: function (state, action) {
            state.sharedStore = action.payload;
        },
        logCta: {
            reducer: function (state, action) {
                var _a = action.payload, cta = _a.cta, label = _a.label;
                state.lastCta = { cta: cta, label: label };
                // tracking actions
                setItem('lastCta', JSON.stringify(action.payload));
            },
            prepare: function (cta, label) {
                return { payload: { cta: cta, label: label } };
            },
        },
        clearState: function (state) {
            state.sharedStore = initialState.sharedStore;
            state.lastCta = initialState.lastCta;
        },
        setFormSubmitted: function (state, action) {
            var _a;
            var formId = get(action, 'payload.formId');
            if (formId)
                state.formSubmitted ? state.formSubmitted[formId] = true : state.formSubmitted = (_a = {}, _a[formId] = true, _a);
        },
        fetchedContextComponent: {
            reducer: function (state, action) {
                state.contextComponents = merge(state.contextComponents, action.payload);
            },
            prepare: function (payload) {
                return { payload: payload };
            }
        }
    },
});
export var broadcastSharedStoreChange = (_a = commonSlice.actions, _a.broadcastSharedStoreChange), setSharedStore = _a.setSharedStore, logCta = _a.logCta, clearState = _a.clearState, fetchedContextComponent = _a.fetchedContextComponent, setFormSubmitted = _a.setFormSubmitted;
export default commonSlice.reducer;
