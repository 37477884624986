import { widgetUrl } from "./urls.staging";
var staging = {
    baseUrl: '',
    version: '3',
    apiKey: '',
    questionnaireId: '',
    appointmentBookingApiKey: 'test_widget_key_nS2shcfNvsMq2MuyTA5W4jxIRjjlph41',
    sentry: {
        widget: "https://8bb380815c9a43c2aa5e3cd221245870@o236458.ingest.sentry.io/1477196",
        bundle: "https://5f72cb7689414643979f5f8a76c731f6@o236458.ingest.sentry.io/4504724861878272"
    },
    widgetUrl: widgetUrl
};
export default staging;
