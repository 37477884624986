var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { configureStore } from '@reduxjs/toolkit';
import get from 'lodash/get';
import set from 'lodash/set';
import omit from 'lodash/omit';
import { getItem, loadState, saveState } from 'conversional-persistent-storage';
import { iframeTemplates } from 'conversional-iframe-communication';
import { preloadUtils } from "conversional-utils";
import rootSaga from './rootSagas';
import createReducers from './createReducers';
import { rtkApi } from '../api/rtkApi';
var IS_DEV = process.env.REACT_APP_JOURNEY_ENV === 'development';
var getInitialState = function (settings, config) {
    if (!config.updated && get(config, 'iframe.viewConfig.template') === iframeTemplates.ENTRY) {
        var loadedState = loadState();
        if (loadedState && !IS_DEV)
            return loadedState;
    }
    var initialState = {
        journeys: {
            journeys: [__assign({}, config)],
            journeyId: config.id,
        },
        locations: {
            locations: [],
        },
        iframe: __assign({ id: settings.iframeId || null }, config.iframe),
        config: omit(settings, 'reduxLoggerPredicate'),
        api: {},
        appointmentBooking: {
            response: {},
            error: {},
            loading: {},
            pendingImages: [],
            uploadedImages: [],
            selectedAppointmentType: null,
            selectedAppointmentLocation: null,
            selectedAppointmentSlot: null,
        }
    };
    if (settings.resultId) {
        initialState.resultId = settings.resultId;
    }
    return initialState;
};
var getExtraMiddlewares = function (_a) {
    var reduxLoggerPredicate = _a.reduxLoggerPredicate;
    return new Promise(function (resolve, reject) {
        var environment = process.env.REACT_APP_JOURNEY_ENV || 'development';
        if (["development", "testing", "remote"].includes(environment)) {
            // using localstorage directly because it is only for the development purpose
            if (getItem('ignore-redux-logger', {
                noAppendix: true,
            })) {
                resolve([]);
                return;
            }
            import('redux-logger').then(function (logger) {
                var loggerOptions = { diff: true, collapsed: true, predicate: null };
                if (reduxLoggerPredicate)
                    loggerOptions.predicate = reduxLoggerPredicate;
                resolve([logger.createLogger(loggerOptions)]);
            });
            return;
        }
        resolve([]);
    });
};
var createStore = function (settings, config) {
    return new Promise(function (resolve, reject) {
        import('redux-saga').then(function (createSagaMiddleware) {
            var sagaMiddleware = createSagaMiddleware.default();
            var middleWare = [sagaMiddleware];
            var reducers = createReducers(settings.extraReducers);
            var initialState = getInitialState(settings, config);
            if (config.initialStateValues) {
                Object.keys(config.initialStateValues).forEach(function (key) {
                    var value = config.initialStateValues[key];
                    set(initialState, key, value);
                });
            }
            getExtraMiddlewares({
                reduxLoggerPredicate: settings.reduxLoggerPredicate,
            }).then(function (extraMiddlewares) {
                var store = configureStore({
                    reducer: reducers,
                    middleware: function (getDefaultMiddleware) {
                        var middleware = getDefaultMiddleware()
                            .concat(rtkApi.middleware)
                            .concat(middleWare)
                            .concat(extraMiddlewares);
                        return middleware;
                    },
                    preloadedState: initialState,
                });
                store.subscribe(function () {
                    var currentState = store.getState();
                    if (settings.forcePersist ||
                        get(currentState, 'iframe.viewConfig.template') === iframeTemplates.ENTRY) {
                        saveState(currentState);
                    }
                });
                window.reduxStore = store;
                sagaMiddleware.run(function () { return rootSaga(settings.extraSagas); });
                resolve(store);
                // Preload the config for the Entry iframe
                if (get(config, 'iframe.viewConfig.template') === iframeTemplates.ENTRY)
                    preloadUtils.processConfigForPreloading(config, store.getState(), store.dispatch);
            });
        });
    });
};
export default createStore;
