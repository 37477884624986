import { JourneysSelectors, ResultsSelectors } from "conversional-journey";
import merge from "lodash/merge";
import * as objectUtils from "./objectUtil";
var updatedStateTime = 0;
var _state = null;
var _runtimeResolved = {};
var UPDATE_TTL = 3 * 1000;
var RECURSION_RECURSION_THRESHOLD = 1;
export var needsResolution = function (data) {
    try {
        return objectUtils.containsDefinition(JSON.stringify(data));
    }
    catch (e) {
        return false;
    }
};
export var resolveDefinitionsRuntime = function (data, counter) {
    if (counter === void 0) { counter = 0; }
    var source = getRuntimeDefinitionSource();
    var resolved = objectUtils.resolveDefinitions(data, source);
    // Recurse if definition is used recursively
    if (counter > RECURSION_RECURSION_THRESHOLD)
        return resolved;
    if (needsResolution(resolved))
        return resolveDefinitionsRuntime(resolved, counter + 1);
    return resolved;
};
var getState = function () {
    // Caching for the given TTL to reduce operation on Redux store runtime
    var now = new Date().getTime();
    if (now - updatedStateTime < UPDATE_TTL && _state) {
        return _state;
    }
    _state = window.reduxStore.getState();
    updatedStateTime = now;
    return _state;
};
var getRuntimeDefinitionSource = function () {
    var state = getState();
    var journey = JourneysSelectors.currentJourney(state);
    var result = ResultsSelectors.selectResultsState(state);
    return merge({}, journey, result);
};
