var _a;
import { createSlice } from '@reduxjs/toolkit';
import { createTheme } from 'conversional-theme';
import appendOrReplace from '../../helper/appendOrReplace';
import { iframeIds, MESSAGETYPES, sendToHost } from 'conversional-iframe-communication';
var initialState = {
    loading: false,
    error: null,
    journeys: [],
    journeyId: null,
};
var journeysSlice = createSlice({
    name: 'journeys',
    initialState: initialState,
    reducers: {
        loadJourney: function (state, action) {
            state.loading = true;
            state.error = null;
        },
        loadJourneySucceeded: function (state, action) {
            var newJourney = action.payload;
            newJourney.theme = createTheme(newJourney.theme);
            appendOrReplace(state.journeys, newJourney);
        },
        loadJourneyFailed: function (state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        setJourney: function (state, action) {
            state.journeyId = action.payload;
        },
        updateJourney: function (state, action) {
            var newJourney = action.payload;
            newJourney.theme = createTheme(newJourney.theme);
            appendOrReplace(state.journeys, newJourney);
        },
        replaceJourney: function (state, action) {
            var newJourney = action.payload;
            newJourney.theme = createTheme(newJourney.theme);
            state.journeys[0] = newJourney;
        },
        decideJourneyOpen: function (state, action) {
            var _a = action.payload, journeyId = _a.journeyId, resultId = _a.resultId, iframeId = _a.iframeId;
            if (resultId) {
                if (iframeId === iframeIds.RESULT) {
                    sendToHost.publish(MESSAGETYPES.JOURNEY_SHOW_RESULT, { resultId: resultId });
                    return;
                }
            }
            if (journeyId)
                if (iframeId === iframeIds.ENTRY)
                    sendToHost.publish(MESSAGETYPES.JOURNEY_SHOW_ENTRY, { journeyId: journeyId });
        },
    },
});
export var loadJourney = (_a = journeysSlice.actions, _a.loadJourney), loadJourneySucceeded = _a.loadJourneySucceeded, loadJourneyFailed = _a.loadJourneyFailed, setJourney = _a.setJourney, updateJourney = _a.updateJourney, replaceJourney = _a.replaceJourney, decideJourneyOpen = _a.decideJourneyOpen;
export default journeysSlice.reducer;
