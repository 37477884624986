var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    timeslots: [],
    reschedulingTimeslots: [],
    loading: false,
    error: null,
};
var timeslotsSlice = createSlice({
    name: 'timeslots',
    initialState: initialState,
    reducers: {
        loadTimeslots: {
            reducer: function (state, action) {
                state.loading = true;
                state.timeslots = [];
            },
            prepare: function (projectId, locale) {
                if (locale === void 0) { locale = 'de'; }
                return {
                    payload: {
                        projectId: projectId,
                        locale: locale,
                    },
                };
            },
        },
        loadTimeslotsSucceeded: function (state, action) {
            state.loading = false;
            state.timeslots = action.payload;
        },
        loadTimeslotsFailed: function (state, action) {
            state.loading = false;
            state.timeslots = [];
        },
        loadReschedulingTimeslots: {
            reducer: function (state, action) {
                state.loading = true;
                state.reschedulingTimeslots = [];
            },
            prepare: function (serviceRequestToken, locale, for_agent) {
                if (locale === void 0) { locale = 'de'; }
                return {
                    payload: {
                        serviceRequestToken: serviceRequestToken,
                        locale: locale,
                        for_agent: for_agent
                    },
                };
            },
        },
        loadTimeslotsRescheduleSucceeded: function (state, action) {
            state.loading = false;
            state.reschedulingTimeslots = action.payload;
        },
        loadReschedulingTimeslotsFailed: function (state, action) {
            state.loading = false;
            state.reschedulingTimeslots = [];
        }
    },
});
export var loadTimeslots = (_a = timeslotsSlice.actions, _a.loadTimeslots), loadTimeslotsSucceeded = _a.loadTimeslotsSucceeded, loadTimeslotsFailed = _a.loadTimeslotsFailed, loadReschedulingTimeslots = _a.loadReschedulingTimeslots, loadTimeslotsRescheduleSucceeded = _a.loadTimeslotsRescheduleSucceeded, loadReschedulingTimeslotsFailed = _a.loadReschedulingTimeslotsFailed;
export default timeslotsSlice.reducer;
