var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import store from 'conversional-persistent-storage';
import * as MESSAGETYPES from './constants/messageTypes';
import parseMessage from './parseMessage';
import formatMessage from './formatMessage';
import get from "lodash/get";
var sendMessage = function (type, payload) {
    if (payload === void 0) { payload = {}; }
    if (window.conversional === 'TEST')
        return;
    var origin;
    if (window.conversional) {
        origin = window.conversional.origin;
        if (origin !== 'undefined') {
            {
                store.setItem('origin', origin);
                var urlParams = new URLSearchParams(origin.split('?')[1]);
                var lead_medium = urlParams.get('utm_medium');
                var lead_source = urlParams.get('utm_source');
                var lead_campaign = urlParams.get('utm_campaign');
                lead_medium && store.setItem("lead_medium", lead_medium);
                lead_source && store.setItem("lead_source", lead_source);
                lead_campaign && store.setItem("lead_campaign", lead_campaign);
            }
        }
        else {
            window.conversional.origin = store.getItem('origin');
        }
    }
    window.parent && window.parent.postMessage(formatMessage(type, payload), origin);
};
// MESSAGES
export function publish(type, payload) {
    if (payload === void 0) { payload = {}; }
    sendMessage(MESSAGETYPES.IFRAME_PUBLISH, __assign({ type: type }, payload));
}
export function toHost(type, payload) {
    if (payload === void 0) { payload = {}; }
    sendMessage(type, payload);
}
export var styleIframe = function (styles, replace) {
    if (replace === void 0) { replace = false; }
    sendMessage(MESSAGETYPES.IFRAME_STYLE, { styles: styles, replace: replace });
};
export var styleTargetContainer = function (styles, replace, silentLog) {
    if (replace === void 0) { replace = false; }
    if (silentLog === void 0) { silentLog = true; }
    sendMessage(MESSAGETYPES.IFRAME_STYLE_TARGET_CONTAINER, { styles: styles, replace: replace, silentLog: silentLog });
};
// Adds a subscriber for an event on the host iframe manager
export var subscribe = function (event, callback) {
    listenTo(event, callback);
    sendMessage(MESSAGETYPES.IFRAME_SUBSCRIBE, event);
};
// Adds a subscriber for an event on the host iframe manager
export var unsubscribe = function (event, callback) {
    stopListening(event, callback);
    sendMessage(MESSAGETYPES.IFRAME_UNSUBSCRIBE, event);
};
export var contentReady = function () {
    sendMessage(MESSAGETYPES.IFRAME_CONTENT_READY);
};
// Triggers a callback when subscribed event is detected
var listenTo = function (event, callback) {
    return window.addEventListener('message', function (message) {
        var _a = parseMessage(message), type = _a.type, payload = _a.payload;
        if (type === event) {
            // @ts-ignore
            callback(payload);
        }
    });
};
var stopListening = function (event, callback) {
    // @ts-ignore
    window.removeEventListener('message', callback);
};
export var focusIframe = function () {
    sendMessage(MESSAGETYPES.IFRAME_FOCUS);
};
export var blurIframe = function () {
    sendMessage(MESSAGETYPES.IFRAME_BLUR);
};
export var rpcResolved = function (id) {
    publish(MESSAGETYPES.RPC_RESOLVED, { id: id });
};
export var trackEvent = function (event) {
    sendMessage(MESSAGETYPES.TRACK_EVENT, event);
};
export var styleParentBody = function (styles, replace) {
    if (replace === void 0) { replace = false; }
    sendMessage(MESSAGETYPES.STYLE_BODY, { styles: styles, replace: replace });
};
export var resetBodyStyles = function () {
    sendMessage(MESSAGETYPES.RESET_STYLE_BODY);
};
export var addStylesTagToHost = function (payload) { return sendMessage(MESSAGETYPES.ADD_STYLES_TO_HOST, payload); };
export var addClassNameToBody = function (className) { return sendMessage(MESSAGETYPES.ADD_CLASSNAME_TO_BODY, { className: className }); };
export var addClassNameToElements = function (className, selectors) { return sendMessage(MESSAGETYPES.ADD_CLASSNAME_TO_ELEMENTS, { className: className, selectors: selectors }); };
export var removeClassNameFromBody = function (className) { return sendMessage(MESSAGETYPES.REMOVE_CLASSNAME_FROM_BODY, { className: className }); };
export var removeClassNameFromElements = function (className, selectors) { return sendMessage(MESSAGETYPES.REMOVE_CLASSNAME_FROM_ELEMENTS, { className: className, selectors: selectors }); };
export var sendFullScreenMessage = function () {
    sendMessage(MESSAGETYPES.TOGGLE_FULLSCREEN);
};
export var ready = function (iframeId) {
    sendMessage(MESSAGETYPES.IFRAME_READY, { iframeId: iframeId });
};
export var destroy = function () {
    sendMessage(MESSAGETYPES.IFRAME_DESTROY);
};
export var iframeOnScreen = function () {
    sendMessage(MESSAGETYPES.IFRAME_ON_SCREEN);
};
export var iframeOffScreen = function () {
    sendMessage(MESSAGETYPES.IFRAME_OFF_SCREEN);
};
export var requestGeoLocation = function () {
    sendMessage(MESSAGETYPES.GEOLOCATION_REQUEST);
};
export var closeJourney = function () {
    publish(MESSAGETYPES.JOURNEY_CLOSE);
};
export var triggerPageTitle = function (enable) {
    sendMessage(MESSAGETYPES.TRIGGER_PAGE_TITLE, __assign({ enable: enable }, (get(window, 'exitIntentPageTitle', {}))));
};
