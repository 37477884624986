var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// TODO: Add types for request
import get from 'lodash/get';
import { call, putResolve, select } from 'redux-saga/effects';
import { getItem, setItem } from 'conversional-persistent-storage';
import API from 'conversional-api';
import { miscUtils, objectUtil } from 'conversional-utils';
import * as ConfigActions from '../store/config/configSlice';
import { ConfigSelectors, JourneysSelectors, ResultsSelectors } from "../index";
var TIME_TO_LIVE = 86400000;
export function wait(ms) {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(function () { return miscUtils.wait(ms); })];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
export function changeResponseErrorMessages(request, status) {
    var error_1, statusCode;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, request()];
            case 1: return [2 /*return*/, _a.sent()];
            case 2:
                error_1 = _a.sent();
                if (error_1.response && error_1.response.status) {
                    for (statusCode in status) {
                        if (error_1.response.status === Number(statusCode)) {
                            throw new Error(status[statusCode]);
                        }
                    }
                }
                throw error_1;
            case 3: return [2 /*return*/];
        }
    });
}
// AbTest has been moved from redux store to local persistent storage to share it between all iframes
export function api(request, options) {
    var _a, apiKey, baseUrl, version, abTest, token, journeyId, journey, useAbTest, api, response, urlPassedABTestToken;
    if (options === void 0) { options = {}; }
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(function (state) { return ({
                    apiKey: ConfigSelectors.selectConfigAPIKey(state),
                    baseUrl: ConfigSelectors.selectConfigBaseURL(state),
                    version: ConfigSelectors.selectConfigVersion(state),
                    token: ResultsSelectors.currentResultId(state),
                    journeyId: JourneysSelectors.currentJourneyId(state),
                    journey: JourneysSelectors.currentJourney(state),
                    abTest: getItem('abTest'),
                }); })];
            case 1:
                _a = _b.sent(), apiKey = _a.apiKey, baseUrl = _a.baseUrl, version = _a.version, abTest = _a.abTest, token = _a.token, journeyId = _a.journeyId, journey = _a.journey;
                useAbTest = abTest || getItem('abTest');
                api = new API(journeyId, apiKey, baseUrl, version, { token: token, abTest: useAbTest });
                return [4 /*yield*/, call(api.request.bind(api), request)];
            case 2:
                response = _b.sent();
                if (options === null || options === void 0 ? void 0 : options.resolveDefinition)
                    response = objectUtil.resolveDefinitions(response, journey);
                urlPassedABTestToken = get(window, 'hostUrlParams.abTest');
                if (urlPassedABTestToken && (urlPassedABTestToken !== response.abTest || urlPassedABTestToken !== abTest)) {
                    ConfigActions.setConfig({ abTest: urlPassedABTestToken });
                    setItem('abTest', urlPassedABTestToken, { ttl: TIME_TO_LIVE });
                }
                if (!(response && response.abTest && abTest !== response.abTest)) return [3 /*break*/, 4];
                return [4 /*yield*/, putResolve(ConfigActions.setConfig({ abTest: response.abTest }))];
            case 3:
                _b.sent();
                setItem('abTest', response.abTest, { ttl: TIME_TO_LIVE });
                _b.label = 4;
            case 4: return [2 /*return*/, response];
        }
    });
}
