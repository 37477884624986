import envConfig from 'conversional-environment';
var mocker = function (args) {
    /** content mock */
    if (args.url.match(/\/content\/context\//)) {
        var context = args.params.context;
        var mockContent = envConfig.mocks.contentConfig[context] || {};
        return {
            data: mockContent,
        };
    }
    /** vendorLocation mock */
    if (args.url.match(/\/timekitProjects\/.*/)) {
        return {
            data: {
                "meta": {
                    "store_location": "Wilhemshavener Straße",
                    "booking_what": "additional key"
                }
            }
        };
    }
};
export default mocker;
