var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { ConfigActions, ConfigSelectors, JourneysSelectors, ResultsSelectors, } from 'conversional-journey';
import { getItem, setItem } from 'conversional-persistent-storage';
import get from 'lodash/get';
import mocker from './mocker';
var environment = process.env.REACT_APP_JOURNEY_ENV || 'development';
var isDevelopment = environment === "development";
var TIME_TO_LIVE = 86400000;
var DEFAULT_KEEP_UNUSED_DATA_FOR = 60;
var shouldUseMockerFunction = function (_a) {
    var url = _a.url;
    return isDevelopment && !url.includes("/content/contexts/");
};
export function makeUrl(_a, path) {
    var journeyId = _a.journeyId, apiKey = _a.apiKey, version = _a.version, baseUrl = _a.baseUrl, token = _a.token;
    var basePath = path.startsWith('http') ? path : "".concat(baseUrl).concat(version).concat(path);
    return basePath
        .replace(/:apiKey/g, apiKey)
        .replace(/:token/g, token)
        .replace(/:journeyId/g, journeyId);
}
var getResponseData = function (response) {
    /** For cases that we had data.data, the whole object was ignored. Will check if 'data' is the only key in
     * the raw response and if it was the only one, we will ignore the object.
     * */
    var data = get(response, 'data', {});
    if (get(data, 'data') && Object.keys(data).length === 1)
        return get(data, 'data');
    return data;
};
var rawBaseQuery = retry(fetchBaseQuery({
    baseUrl: '',
}), { maxRetries: 3 });
var dynamicBaseQuery = function (args, api, extraOptions) { return __awaiter(void 0, void 0, void 0, function () {
    var state, apiKey, baseUrl, version, token, journeyId, abTest, extraParams, adjustedUrl, adjustedArgs, response, responseAbTest, urlPassedABTestToken, data, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = api.getState();
                apiKey = ConfigSelectors.selectConfigAPIKey(state);
                baseUrl = ConfigSelectors.selectConfigBaseURL(state);
                version = ConfigSelectors.selectConfigVersion(state);
                token = ResultsSelectors.currentResultId(state);
                journeyId = JourneysSelectors.currentJourneyId(state);
                abTest = getItem('abTest');
                extraParams = __assign(__assign({}, args.params), { token: token, abTest: abTest, apiKey: apiKey });
                // gracefully handle scenarios where data to generate the URL is missing
                if (!journeyId) {
                    return [2 /*return*/, {
                            error: {
                                status: 400,
                                statusText: 'Bad Request',
                                data: 'No journeyId received',
                            },
                        }];
                }
                if (!apiKey) {
                    return [2 /*return*/, {
                            error: {
                                status: 400,
                                statusText: 'Bad Request',
                                data: 'No apiKey received',
                            },
                        }];
                }
                if (!baseUrl) {
                    return [2 /*return*/, {
                            error: {
                                status: 400,
                                statusText: 'Bad Request',
                                data: 'No baseUrl received',
                            },
                        }];
                }
                if (!version) {
                    return [2 /*return*/, {
                            error: {
                                status: 400,
                                statusText: 'Bad Request',
                                data: 'No version received',
                            },
                        }];
                }
                adjustedUrl = makeUrl({ apiKey: apiKey, baseUrl: baseUrl, version: version, token: token, journeyId: journeyId }, args.url);
                adjustedArgs = __assign(__assign({}, args), { url: adjustedUrl, params: __assign({}, extraParams) });
                //   Interceptors
                if (shouldUseMockerFunction({ url: adjustedUrl })) {
                    return [2 /*return*/, mocker(args)];
                }
                return [4 /*yield*/, rawBaseQuery(adjustedArgs, api, extraOptions)];
            case 1:
                response = _a.sent();
                responseAbTest = get(response, 'data.abTest', null);
                urlPassedABTestToken = get(window, 'hostUrlParams.abTest');
                if (urlPassedABTestToken && (urlPassedABTestToken !== responseAbTest || urlPassedABTestToken !== abTest)) {
                    ConfigActions.setConfig({ abTest: urlPassedABTestToken });
                    setItem('abTest', urlPassedABTestToken, { ttl: TIME_TO_LIVE });
                }
                else if (response && responseAbTest && abTest !== responseAbTest) {
                    ConfigActions.setConfig({ abTest: responseAbTest });
                    setItem('abTest', responseAbTest, { ttl: TIME_TO_LIVE });
                }
                data = getResponseData(response);
                error = get(response, 'data.error') || get(response, 'error');
                if (error) {
                    return [2 /*return*/, {
                            error: error
                        }];
                }
                return [2 /*return*/, {
                        data: data
                    }];
        }
    });
}); };
export var rtkApi = createApi({
    reducerPath: 'api',
    baseQuery: dynamicBaseQuery,
    keepUnusedDataFor: isDevelopment ? 0 : DEFAULT_KEEP_UNUSED_DATA_FOR,
    endpoints: function () { return ({}); },
});
