var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { putResolve, select, takeLatest, takeLeading } from 'redux-saga/effects';
import merge from 'lodash/merge';
import get from 'lodash/get';
import { produce } from "immer";
import { MESSAGETYPES, sendToHost } from 'conversional-iframe-communication';
import { captureException } from 'conversional-exceptions';
import { customEndpointApi, customPostRequest, deleteStylesApi, doLikeRequest, getImagesApi, getProductsApi, getResultCalendarLinksApi, getResultLikeItems, getResultsApi, overwriteStylesApi, sendAnswersApi, sendFeedbackApi, sendStylesApi, urlHelpers, } from 'conversional-api';
import { preloadUtils } from "conversional-utils";
import * as Actions from '../resultsSlice';
import * as sagaUtils from '../../../helper/sagaUtils';
import { currentResultId, selectResultProfile } from '../selectors';
import { currentJourneyId } from '../../journeys/selectors';
import { selectExternalData, selectHostQueryParams } from '../../config/selectors';
import * as ProcessesActions from '../../processes/processesSlice';
import { getItem, setItem } from 'conversional-persistent-storage';
import * as Events from '../../tracking/events';
import * as TrackingActions from '../../tracking/trackingSlice';
import { getProcessDataById, getProcessDataById_withDefaultFirstProcessData, selectCurrentProcessData } from "../../processes/selectors";
var SCROLL_TO_APPOINTMENT_QUERY_KEY = 'conversional.event.scrollToAppointment';
var RESULT_APPOINTMENT_MODAL_ABTEST_HASH = 'wjL5yXLq';
var TIME_TO_LIVE = 86400000;
// region Standalone appointment booking ab test
// Temporary logic for testing appointment booking from email
var EXCLUDED_RESULT_APPOINTMENT_BOOKING_AB_TEST_JOURNEYS = ['WzErjYE1', 'gx0Y3xo1', 'gl0wQZo5', 'WzErXOL1', 'q9ExQX0O', 'NB0Mko6n'];
function standaloneResultAppointmentBookingAbTestDisabled(journeyId) {
    return EXCLUDED_RESULT_APPOINTMENT_BOOKING_AB_TEST_JOURNEYS.includes(journeyId);
}
function getGlobalResultAbTestPayload(queryParams, journeyId) {
    if (standaloneResultAppointmentBookingAbTestDisabled(journeyId))
        return;
    if (queryParams[SCROLL_TO_APPOINTMENT_QUERY_KEY]) {
        if (new Date().getTime() % 2 === 0)
            return RESULT_APPOINTMENT_MODAL_ABTEST_HASH;
    }
}
// endregion
function readCta() {
    var lastCta = getItem('lastCta');
    var cta;
    if (!lastCta) {
        return;
    }
    try {
        cta = JSON.parse(lastCta);
    }
    catch (error) {
        return;
    }
    return cta;
}
export function openCurrentResultSaga() {
    var resultId;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(currentResultId)];
            case 1:
                resultId = _a.sent();
                sendToHost.publish(MESSAGETYPES.JOURNEY_SHOW_RESULT, { resultId: resultId });
                return [2 /*return*/];
        }
    });
}
export function openResultSaga(action) {
    sendToHost.publish(MESSAGETYPES.JOURNEY_SHOW_RESULT, { resultId: action.payload });
}
function loadResultSaga(action) {
    var state, hostQueryParams, journeyId, abTest, result, error_1;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 5, , 7]);
                state = window.reduxStore.getState();
                hostQueryParams = selectHostQueryParams(state);
                journeyId = currentJourneyId(state);
                abTest = getGlobalResultAbTestPayload(hostQueryParams, journeyId);
                abTest && setItem('abTest', abTest, { ttl: TIME_TO_LIVE });
                return [4 /*yield*/, sagaUtils.api(getResultsApi(), {
                        resolveDefinition: true
                    })];
            case 1:
                result = _b.sent();
                if (!result) return [3 /*break*/, 3];
                preloadUtils.preloadResultConfig(result, state, window.reduxStore.dispatch);
                return [4 /*yield*/, putResolve(Actions.loadResultSucceeded(action.payload, result))];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3: throw new Error('Could not load journey config');
            case 4: return [3 /*break*/, 7];
            case 5:
                error_1 = _b.sent();
                // Do not do nothing to not disrupt the user
                captureException(error_1, { saga: 'loadResult', action: action });
                return [4 /*yield*/, putResolve(Actions.loadResultFailed({
                        message: error_1.message,
                        resultId: action.payload,
                        status: (_a = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _a === void 0 ? void 0 : _a.status
                    }))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function loadGalleryImages(action) {
    var resultId, resultUserProfile, images, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 7, , 9]);
                return [4 /*yield*/, select(currentResultId)
                    /**
                     * We will pass the configuration which is in the configuration (search for suppliers, parameters like tags)
                     * and also the user preferences which is in the result configuration and we store it in the redux store
                     * to the backend API. Backend will process the filters and will respond with the right images for
                     * both the partner and the user. So the result is more meaningful!
                     * */
                ];
            case 1:
                resultId = _a.sent();
                return [4 /*yield*/, select(selectResultProfile)];
            case 2:
                resultUserProfile = _a.sent();
                return [4 /*yield*/, sagaUtils.api(getImagesApi({
                        page: 1,
                        limit: get(action.payload, 'searchProps.limit', 10),
                        result_alias: resultId,
                        provider_id: get(action.payload, 'searchProps.providerId'),
                        profile: {
                            strategy_on_same_keys: get(action.payload, 'searchProps.profileSearchStrategy', 'merge'),
                            data: resultUserProfile,
                        },
                        parameters: get(action.payload, 'searchProps.parameters', {}),
                    }))];
            case 3:
                images = _a.sent();
                if (!images) return [3 /*break*/, 5];
                return [4 /*yield*/, putResolve(Actions.loadGalleryImagesSucceeded(images, resultId))];
            case 4:
                _a.sent();
                return [3 /*break*/, 6];
            case 5: throw new Error('Could not load gallery images');
            case 6: return [3 /*break*/, 9];
            case 7:
                error_2 = _a.sent();
                // Do not do nothing to not disrupt the user
                captureException(error_2, { saga: 'loadImages', action: action });
                return [4 /*yield*/, putResolve(Actions.loadGalleryImagesFailed(error_2.message))];
            case 8:
                _a.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function loadProducts(action) {
    var resultId, resultUserProfile, products, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, select(currentResultId)];
            case 1:
                resultId = _a.sent();
                return [4 /*yield*/, select(selectResultProfile)];
            case 2:
                resultUserProfile = _a.sent();
                return [4 /*yield*/, sagaUtils.api(getProductsApi({
                        page: get(action.payload, 'searchProps.page', 1),
                        limit: get(action.payload, 'searchProps.limit', 3),
                        result_alias: resultId,
                        provider_id: get(action.payload, 'searchProps.providerId'),
                        profile: {
                            strategy_on_same_keys: get(action.payload, 'searchProps.profileSearchStrategy', 'merge'),
                            data: resultUserProfile,
                        },
                        parameters: get(action.payload, 'searchProps.parameters', {}),
                    }))];
            case 3:
                products = _a.sent();
                return [4 /*yield*/, putResolve(Actions.loadProductsSucceeded(products, resultId))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                error_3 = _a.sent();
                // Do not do nothing to not disrupt the user
                captureException(error_3, { saga: 'loadProducts', action: action });
                return [4 /*yield*/, putResolve(Actions.loadProductsFailed(error_3.message))];
            case 6:
                _a.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function loadResultLikes() {
    var resultId, resultLikes, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 6]);
                return [4 /*yield*/, select(currentResultId)];
            case 1:
                resultId = _a.sent();
                return [4 /*yield*/, sagaUtils.api(getResultLikeItems({
                        resultId: resultId,
                    }))];
            case 2:
                resultLikes = _a.sent();
                return [4 /*yield*/, putResolve(Actions.loadResultLikesSucceeded(resultLikes, resultId))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                error_4 = _a.sent();
                // Do not do nothing to not disrupt the user
                captureException(error_4, { saga: 'loadResultLikes' });
                return [4 /*yield*/, putResolve(Actions.loadResultLikesFailed(error_4.message))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
export function updateStylesSaga(action) {
    var _a, resultId, styles, response, error_5;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, resultId = _a.resultId, styles = _a.styles;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                return [4 /*yield*/, putResolve(Actions.updateStylesSucceeded(resultId, styles))];
            case 2:
                _b.sent();
                return [4 /*yield*/, sagaUtils.api(sendStylesApi({ resultId: resultId, styles: styles }))];
            case 3:
                response = _b.sent();
                if (!response) return [3 /*break*/, 5];
                return [4 /*yield*/, putResolve(Actions.stylesUpdated())];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_5 = _b.sent();
                // TODO: Rollback state change
                captureException(error_5, { saga: 'updateStyles', action: action });
                return [4 /*yield*/, putResolve(Actions.updateStylesFailed(resultId, styles))];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function likeSaga(action) {
    var _a, itemType, itemId, like, resultId, response, error_6;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, itemType = _a.itemType, itemId = _a.itemId, like = _a.like;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                return [4 /*yield*/, select(currentResultId)];
            case 2:
                resultId = _b.sent();
                return [4 /*yield*/, sagaUtils.api(doLikeRequest({ resultId: resultId, itemType: itemType, itemId: itemId, like: like }))];
            case 3:
                response = _b.sent();
                if (!response) return [3 /*break*/, 5];
                return [4 /*yield*/, putResolve(Actions.doLikeSucceeded({ respone: JSON.stringify(response), itemId: itemId }))];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_6 = _b.sent();
                // TODO: Rollback state change
                captureException(error_6, { saga: 'doLike', action: action });
                return [4 /*yield*/, putResolve(Actions.doLikeFailed({ message: error_6.message, itemId: itemId }))];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function overwriteStylesSaga(action) {
    var _a, resultId, styles, response, error_7;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, resultId = _a.resultId, styles = _a.styles;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 5, , 7]);
                return [4 /*yield*/, sagaUtils.api(overwriteStylesApi({ resultId: resultId, styles: styles }))];
            case 2:
                response = _b.sent();
                if (!response) return [3 /*break*/, 4];
                return [4 /*yield*/, putResolve(Actions.overwriteStylesSucceeded(resultId, styles))];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                error_7 = _b.sent();
                captureException(error_7, { saga: 'overwriteStyles', action: action });
                return [4 /*yield*/, putResolve(Actions.overwriteStylesFailed(error_7.message))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
export function deleteStylesSaga(action) {
    var _a, resultId, styles, response, error_8;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, resultId = _a.resultId, styles = _a.styles;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                return [4 /*yield*/, putResolve(Actions.deleteStylesSucceeded(resultId, styles))];
            case 2:
                _b.sent();
                return [4 /*yield*/, sagaUtils.api(deleteStylesApi({ resultId: resultId, styles: styles }))];
            case 3:
                response = _b.sent();
                if (!response) return [3 /*break*/, 5];
                return [4 /*yield*/, putResolve(Actions.stylesUpdated())];
            case 4:
                _b.sent();
                _b.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_8 = _b.sent();
                // TODO: Rollback state change
                captureException(error_8, { saga: 'deleteStyles', action: action });
                return [4 /*yield*/, putResolve(Actions.deleteStylesFailed(resultId, styles))];
            case 7:
                _b.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export function sendFormSaga(action) {
    var _a, endpoint, formData, options, processData, response, error_9;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, endpoint = _a.endpoint, formData = _a.formData, options = _a.options;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 8, , 10]);
                if (!urlHelpers.isAbsoluteUrl(endpoint) && endpoint.substr(0, 1) != '/')
                    endpoint = "/".concat(endpoint);
                if (!(options === null || options === void 0 ? void 0 : options.includeProcessData)) return [3 /*break*/, 3];
                return [4 /*yield*/, select(selectCurrentProcessData)];
            case 2:
                processData = _b.sent();
                formData = __assign(__assign({}, formData), processData);
                _b.label = 3;
            case 3:
                updateCustomerWithUTMParams(formData);
                return [4 /*yield*/, sagaUtils.changeResponseErrorMessages(function () { return sagaUtils.api(customEndpointApi(endpoint, formData)); }, {
                        400: '400 ERROR',
                        404: '404 ERROR',
                        500: '500 ERROR',
                    })];
            case 4:
                response = _b.sent();
                if (!!response) return [3 /*break*/, 5];
                throw new Error('SEND FORM RETURNED EMPTY');
            case 5: return [4 /*yield*/, putResolve(Actions.sendFormSucceeded(response))];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 10];
            case 8:
                error_9 = _b.sent();
                return [4 /*yield*/, putResolve(Actions.sendFormFailed(error_9.message))];
            case 9:
                _b.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
export function sendFeedback(action) {
    var _a, rating, resultId, comment;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, rating = _a.rating, resultId = _a.resultId, comment = _a.comment;
                return [4 /*yield*/, sagaUtils.api(sendFeedbackApi(rating || null, resultId, comment || null))];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
var updateCustomerWithUTMParams = function (data) {
    var utmQueryParams = extractUTMParameters();
    if (data.Customer) {
        UTM_PARAMS.forEach(function (param) {
            if (utmQueryParams[param]) {
                data.Customer[param] = utmQueryParams[param];
            }
        });
    }
};
export function sendFormDataToURL(action) {
    var _a, url, processId, data, response, error_10;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = action.payload, url = _a.url, processId = _a.processId;
                return [4 /*yield*/, select(function (state) { return getProcessDataById(state, processId); })];
            case 1:
                data = _b.sent();
                updateCustomerWithUTMParams(data);
                _b.label = 2;
            case 2:
                _b.trys.push([2, 5, , 7]);
                return [4 /*yield*/, customPostRequest(url, data)];
            case 3:
                response = _b.sent();
                return [4 /*yield*/, putResolve(Actions.sendFormToUrlSucceeded(response))];
            case 4:
                _b.sent();
                return [3 /*break*/, 7];
            case 5:
                error_10 = _b.sent();
                captureException(error_10, { saga: 'sendFormDataToUrl', url: url, data: data });
                return [4 /*yield*/, putResolve(Actions.sendFormToUrlFailed(error_10.message || "Send form to ".concat(url, " failed")))];
            case 6:
                _b.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function _removeIdFromKeys(data) {
    var newData = {};
    Object.keys(data || {}).forEach(function (index) {
        newData[String(index.replace('Id', ''))] = data[index];
    });
    return newData;
}
var UTM_PARAMS_LEAD_MEDIUM = 'lead_medium';
var UTM_PARAMS_LEAD_SOURCE = 'lead_source';
var UTM_PARAMS_LEAD_CAMPAIGN = 'lead_campaign';
var UTM_PARAMS = [UTM_PARAMS_LEAD_MEDIUM, UTM_PARAMS_LEAD_CAMPAIGN, UTM_PARAMS_LEAD_SOURCE];
function extractUTMParameters() {
    return {
        lead_medium: getItem(UTM_PARAMS_LEAD_MEDIUM),
        lead_source: getItem(UTM_PARAMS_LEAD_SOURCE),
        lead_campaign: getItem(UTM_PARAMS_LEAD_CAMPAIGN),
    };
}
function sendAnswers(actionPayload) {
    var payload, apiPayload, processData, journeyHash_1, externalData_1, response, lastCta, error_11, processId, defaultErrorMsg;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = actionPayload.payload;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 10, , 14]);
                return [4 /*yield*/, select(function (state) { return getProcessDataById_withDefaultFirstProcessData(state, payload.processId); })];
            case 2:
                processData = _a.sent();
                return [4 /*yield*/, select(currentJourneyId)];
            case 3:
                journeyHash_1 = _a.sent();
                return [4 /*yield*/, select(selectExternalData)
                    /**
                     * Using Immer's produce function to create a new state based on processData
                    `produce` takes the original state (processData) and applies the mutations defined in the draft function.
                    The mutations are applied immutably, meaning that the original state is not modified, and a new state is returned.
                     */
                ];
            case 4:
                externalData_1 = _a.sent();
                /**
                 * Using Immer's produce function to create a new state based on processData
                `produce` takes the original state (processData) and applies the mutations defined in the draft function.
                The mutations are applied immutably, meaning that the original state is not modified, and a new state is returned.
                 */
                apiPayload = produce(processData, function (draft) {
                    draft.Answers = produce(draft.Answers, function (answerDraft) {
                        answerDraft = _removeIdFromKeys(answerDraft);
                    });
                    if (draft.meta) {
                        var externalDataMeta = get(externalData_1, 'meta', {});
                        draft.meta = merge(externalDataMeta, draft.meta);
                    }
                    draft.journeyHash = journeyHash_1;
                    draft.questionnaireHash = payload.questionnaireHash;
                    updateCustomerWithUTMParams(draft);
                });
                return [4 /*yield*/, sagaUtils.api(sendAnswersApi(apiPayload))];
            case 5:
                response = _a.sent();
                if (!response) return [3 /*break*/, 8];
                return [4 /*yield*/, putResolve(Actions.sendAnswersSucceeded(response))
                    // tracking actions
                ];
            case 6:
                _a.sent();
                lastCta = readCta();
                if (!lastCta) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, putResolve(TrackingActions.event(__assign(__assign({}, Events.CONVERTINGCTA), { action: lastCta.cta, label: lastCta.label })))];
            case 7:
                _a.sent();
                return [3 /*break*/, 9];
            case 8: throw new Error('Send answers response is empty');
            case 9: return [3 /*break*/, 14];
            case 10:
                error_11 = _a.sent();
                captureException(error_11, { saga: 'sendAnswers', apiPayload: apiPayload, action: actionPayload });
                processId = payload.processId;
                if (!processId) return [3 /*break*/, 12];
                return [4 /*yield*/, putResolve(ProcessesActions.previousStep({ processId: processId }))];
            case 11:
                _a.sent();
                _a.label = 12;
            case 12:
                defaultErrorMsg = 'Something went wrong. Please try again later.';
                return [4 /*yield*/, putResolve(Actions.sendAnswersFailed(error_11['error_user_message'] ? error_11['error_user_message'][0] : defaultErrorMsg))];
            case 13:
                _a.sent();
                return [3 /*break*/, 14];
            case 14: return [2 /*return*/];
        }
    });
}
function loadResultCalendarLinks(action) {
    var calendarLinks, error_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!action.payload.hash)
                    return [2 /*return*/];
                return [4 /*yield*/, sagaUtils.api(getResultCalendarLinksApi({
                        hash: action.payload.hash,
                    }))];
            case 1:
                calendarLinks = _a.sent();
                _a.label = 2;
            case 2:
                _a.trys.push([2, 6, , 8]);
                if (!calendarLinks) return [3 /*break*/, 4];
                return [4 /*yield*/, putResolve(Actions.loadCalendarLinksSucceeded(calendarLinks))];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4: throw new Error('No calendar links available');
            case 5: return [3 /*break*/, 8];
            case 6:
                error_12 = _a.sent();
                // Do not do nothing to not disrupt the user
                captureException(error_12, { saga: 'loadServiceRequestCalendarLinks' });
                return [4 /*yield*/, putResolve(Actions.loadCalendarLinksFailed(error_12))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export default function actionWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(Actions.sendFormToUrl.type, sendFormDataToURL)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(Actions.openResult.type, openResultSaga)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(Actions.updateStyles.type, updateStylesSaga)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatest(Actions.overwriteStyles.type, overwriteStylesSaga)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(Actions.deleteStyles.type, deleteStylesSaga)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(Actions.openCurrentResult.type, openCurrentResultSaga)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLeading(Actions.loadResult.type, loadResultSaga)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLeading(Actions.sendForm.type, sendFormSaga)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeLeading(Actions.sendFeedback.type, sendFeedback)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeLeading(Actions.loadGalleryImages.type, loadGalleryImages)];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeLeading(Actions.loadProducts.type, loadProducts)];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeLeading(Actions.loadResultLikes.type, loadResultLikes)];
            case 12:
                _a.sent();
                return [4 /*yield*/, takeLeading(Actions.doLike.type, likeSaga)];
            case 13:
                _a.sent();
                return [4 /*yield*/, takeLeading(Actions.sendAnswers.type, sendAnswers)];
            case 14:
                _a.sent();
                return [4 /*yield*/, takeLatest(Actions.loadResultCalendarLinks.type, loadResultCalendarLinks)];
            case 15:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
