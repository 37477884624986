import { createSelector } from '@reduxjs/toolkit';
import get from 'lodash/get';
var selectResults = function (state) { return state.results; };
export var selectResultsState = function (state) { return state.results.result; };
export var currentResultId = function (state) {
    return state.results.resultId || state.results.resultId;
};
export var getResultIdFromExternalConfig = function (state) {
    // Backend may change dynmic query strings so this array will make sure that the alias is filled.
    var keys = ['resultId', 'resultToken', 'result_alias'];
    for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
        var key = keys_1[_i];
        var possibleResultId = get(state, "config.external.data.".concat(key));
        if (possibleResultId)
            return possibleResultId;
    }
};
export var selectResultProfile = createSelector(selectResultsState, function (result) { return (result === null || result === void 0 ? void 0 : result.profile) || null; });
export var selectResultProfileComment = createSelector(selectResultProfile, function (profile) { return profile.comment || ''; });
export var getCurrentResultGalleryImages = createSelector(selectResultsState, function (result) { return (result && result.galleryImages) || []; });
//Todo : check any possible dependency and fix and remove
export var currentProfileAttribute = function (state, style, feature) {
    var result = state.results.result;
    if (!result) {
        return false;
    }
    var features = result.profile[style] || [];
    if (!Array.isArray(features)) {
        return false;
    }
    return features.includes(feature);
};
export var getProducts = function (state) {
    var result = state.results.result;
    return (result === null || result === void 0 ? void 0 : result.products) || [];
};
export var getNoMoreProducts = function (state) {
    var result = state.results.result;
    return (result === null || result === void 0 ? void 0 : result.noMoreProducts) || false;
};
export var getLoadingProducts = function (state) {
    var result = state.results.result;
    return (result === null || result === void 0 ? void 0 : result.loadingProducts) || false;
};
export var selectSendFormLoading = function (state) {
    return state.results.formLoading;
};
export var currentProductLikeLoading = function (state, itemId) {
    var result = state.results.result;
    if (!result) {
        return false;
    }
    return result.likeLoading ? result.likeLoading[itemId] || false : false;
};
export var selectResultLoading = createSelector(selectResults, function (result) { return result.loading; });
export var selectResultError = createSelector(selectResults, function (result) { return result.error; });
export var selectResultStyleError = createSelector(selectResults, function (result) { return result.stylesError; });
export var selectResultStyleLoading = createSelector(selectResults, function (result) { return result.stylesLoading; });
export var selectStylesUpdated = createSelector(selectResults, function (result) { return result.stylesUpdated; });
export var resultCalendarLinks = createSelector(selectResults, function (result) { return result.calendarLinks; });
export var resultABTest = createSelector(selectResultsState, function (result) { var _a; return (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.abTest; });
