import { useState, useEffect } from 'react';
var useIsMobile = function () {
    var _a = useState(false), isMobile = _a[0], setIsMobile = _a[1];
    useEffect(function () {
        setIsMobile(window.matchMedia('(max-width: 600px)').matches);
        var handleResize = function () {
            setIsMobile(window.matchMedia('(max-width: 600px)').matches);
        };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return isMobile;
};
export default useIsMobile;
