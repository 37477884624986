import { publish } from './index';
var urlEventsHandler = function () {
    var params = window.hostUrlParams || {};
    var events = [];
    var eventProcessor = function (publishFunction) { return ({
        'scrollToAppointment': function () {
            setTimeout(function () {
                publishFunction();
            }, 1000);
        },
        'default': publishFunction
    }); };
    // eslint-disable-next-line no-unused-vars
    for (var param in params) {
        if (/^conversional\.event\./.test(param)) {
            events.push({ name: param.replace(/^conversional\.event\./, '') });
        }
        if (/^conversional_event_/.test(param)) {
            var eventName = transformUnderscoreToDot(param);
            events.push({ name: eventName });
        }
    }
    var _loop_1 = function (event) {
        var publishFunctionMap = eventProcessor(function () { return publish(event.name, {}); });
        publishFunctionMap[event.name] ? publishFunctionMap[event.name]() : publishFunctionMap.default();
    };
    // eslint-disable-next-line no-unused-vars
    for (var _i = 0, events_1 = events; _i < events_1.length; _i++) {
        var event = events_1[_i];
        _loop_1(event);
    }
};
/**
 * Replaces _ with . because Wordpress is rewriting dot parameters to underscore parameters
 * @param string url parameter
 */
var transformUnderscoreToDot = function (param) {
    return param.replace(/^conversional_event_/, '').replace(/_/, '.');
};
export default urlEventsHandler;
