import { useState, useEffect } from 'react';
var useIsDesktop = function () {
    var _a = useState(false), isDesktop = _a[0], setIsDesktop = _a[1];
    useEffect(function () {
        setIsDesktop(window.matchMedia("(min-width: 600px)").matches);
        var handleResize = function () {
            setIsDesktop(window.matchMedia("(min-width: 600px)").matches);
        };
        window.addEventListener('resize', handleResize);
        return function () {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return isDesktop;
};
export default useIsDesktop;
