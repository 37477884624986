var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { takeLatest, putResolve, select } from 'redux-saga/effects';
import { getAvailabilityApi, getAvailabilityForReschedulingApi } from 'conversional-api';
import { captureException } from 'conversional-exceptions';
import { formatDate, formatTime } from '../../../helper/dateAndTime';
import * as Actions from '../timeslotsSlice';
import { selectLocale } from '../../journeys/selectors';
import * as sagaUtils from '../../../helper/sagaUtils';
var USE_TIMEZONE = 'Europe/Berlin';
function formatTimeslots(locale, timeslots) {
    return timeslots.map(function (timeslot) {
        var date = Date.parse(timeslot.start);
        timeslot.start_date = formatDate(date, locale.lang, undefined);
        timeslot.start_time = formatTime(date, locale.lang, locale.timezone);
        return timeslot;
    });
}
function loadReschedulingTimeslots(action) {
    var timeslots, locale, formattedTimeslots, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!action.payload.serviceRequestToken)
                    return [2 /*return*/];
                return [4 /*yield*/, sagaUtils.api(getAvailabilityForReschedulingApi({
                        serviceRequestToken: action.payload.serviceRequestToken,
                        for_agent: action.payload.for_agent,
                        timezone: USE_TIMEZONE,
                    }))];
            case 1:
                timeslots = _a.sent();
                return [4 /*yield*/, select(selectLocale)];
            case 2:
                locale = _a.sent();
                _a.label = 3;
            case 3:
                _a.trys.push([3, 7, , 9]);
                if (!timeslots) return [3 /*break*/, 5];
                formattedTimeslots = formatTimeslots(locale, timeslots);
                return [4 /*yield*/, putResolve(Actions.loadTimeslotsRescheduleSucceeded(formattedTimeslots))];
            case 4:
                _a.sent();
                return [3 /*break*/, 6];
            case 5: throw new Error('No timeslots available');
            case 6: return [3 /*break*/, 9];
            case 7:
                error_1 = _a.sent();
                // Do not do nothing to not disrupt the user
                captureException(error_1, { saga: 'loadTimeslots' });
                return [4 /*yield*/, putResolve(Actions.loadReschedulingTimeslotsFailed(error_1))];
            case 8:
                _a.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
function loadTimeslots(action) {
    var timeslots, locale, formattedTimeslots, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!action.payload.projectId)
                    return [2 /*return*/];
                _a.label = 1;
            case 1:
                _a.trys.push([1, 7, , 9]);
                return [4 /*yield*/, sagaUtils.api(getAvailabilityApi({ projectId: action.payload.projectId, timezone: USE_TIMEZONE }))];
            case 2:
                timeslots = _a.sent();
                return [4 /*yield*/, select(selectLocale)];
            case 3:
                locale = _a.sent();
                if (!timeslots) return [3 /*break*/, 5];
                formattedTimeslots = formatTimeslots(locale, timeslots);
                return [4 /*yield*/, putResolve(Actions.loadTimeslotsSucceeded(formattedTimeslots))];
            case 4:
                _a.sent();
                return [3 /*break*/, 6];
            case 5: throw new Error('No timeslots available');
            case 6: return [3 /*break*/, 9];
            case 7:
                error_2 = _a.sent();
                // Do not do nothing to not disrupt the user
                captureException(error_2, { saga: 'loadTimeslots' });
                return [4 /*yield*/, putResolve(Actions.loadTimeslotsFailed(error_2))];
            case 8:
                _a.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
export default function actionWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(Actions.loadTimeslots.type, loadTimeslots)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(Actions.loadReschedulingTimeslots.type, loadReschedulingTimeslots)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
