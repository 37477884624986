import merge from 'lodash/merge';
import { JourneysSelectors } from 'conversional-journey';
export var updateJourneyConfig = function (_a) {
    var _b;
    var config = _a.config, key = _a.key;
    var storeState = window.reduxStore.getState();
    var currentJourneyConfig = JourneysSelectors.currentJourney(storeState);
    var updatedConfig = merge({}, currentJourneyConfig, (_b = {}, _b[key] = config, _b));
    return updatedConfig;
};
export var updateJourneyConfigForDev = function (_a) {
    var config = _a.config;
    var storeState = window.reduxStore.getState();
    var currentJourneyConfig = JourneysSelectors.currentJourney(storeState);
    if (currentJourneyConfig.id !== config.id)
        console.error('Journey id does not match the file name. Please check the journey id and try again.');
    return config;
};
