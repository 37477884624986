var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createSlice } from '@reduxjs/toolkit';
var initialState = {};
var iframeSlice = createSlice({
    name: 'iframe',
    initialState: initialState,
    reducers: {
        setContent: {
            reducer: function (state, action) {
                var _a = action.payload, viewProps = _a.viewProps, viewConfig = _a.viewConfig;
                state = __assign(__assign({}, state), { viewProps: viewProps, viewConfig: viewConfig });
            },
            prepare: function (viewProps, viewConfig) {
                return {
                    payload: {
                        viewProps: viewProps,
                        viewConfig: viewConfig,
                    },
                };
            },
        },
    },
});
export var setContent = iframeSlice.actions.setContent;
export default iframeSlice.reducer;
