var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import isEmpty from 'lodash/isEmpty';
import { createSlice } from '@reduxjs/toolkit';
import { MESSAGETYPES, sendToHost } from 'conversional-iframe-communication';
var initialState = {
    result: null,
    resultId: null,
    formResult: {},
    loading: false,
    error: null,
    formLoading: false,
    formError: null,
    overWriteStylesLoading: false,
    overWriteStylesError: null,
    stylesError: null,
    stylesLoading: false,
    stylesUpdated: false,
    calendarLinks: null
};
var checkResult = function (result) {
    if (!result)
        return;
};
var resultsSlice = createSlice({
    name: 'results',
    initialState: initialState,
    reducers: {
        sendAnswers: {
            reducer: function (state) {
                state.loading = true;
                state.resultId = null;
            },
            prepare: function (questionnaireHash, processId) {
                return {
                    payload: {
                        questionnaireHash: questionnaireHash,
                        processId: processId,
                    },
                };
            },
        },
        sendAnswersSucceeded: {
            reducer: function (state, action) {
                var token = action.payload.token;
                state.loading = false;
                state.resultId = token;
                sendToHost.publish(MESSAGETYPES.JOURNEY_CREATE_RESULT, {
                    token: token,
                });
            },
            prepare: function (response) {
                var token = response.token;
                return {
                    payload: {
                        token: token,
                    },
                };
            },
        },
        sendAnswersFailed: function (state, action) {
            state.loading = false;
            state.error = action.payload;
        },
        loadResult: function (state, action) {
            state.resultId = action.payload;
            state.loading = true;
        },
        loadResultSucceeded: {
            reducer: function (state, action) {
                var _a = action.payload, result = _a.result, resultId = _a.resultId;
                result.id = resultId;
                state.result = result;
                state.loading = false;
            },
            prepare: function (resultId, result) {
                return {
                    payload: {
                        resultId: resultId,
                        result: result,
                    },
                };
            },
        },
        loadResultFailed: function (state, action) {
            state.error = action.payload;
            state.loading = false;
        },
        openResult: function (state, action) {
            state.resultId = action.payload;
            return state;
        },
        openCurrentResult: function (state) { },
        updateStyles: {
            reducer: function (state, action) {
                state.stylesLoading = true;
                state.stylesUpdated = false;
            },
            prepare: function (resultId, styles) {
                return {
                    payload: {
                        resultId: resultId,
                        styles: styles,
                    },
                };
            },
        },
        updateStylesSucceeded: {
            reducer: function (state, action) {
                var styles = action.payload.styles;
                var result = state.result;
                checkResult(result);
                var currentProfile = result.profile;
                var keys = Object.keys(styles);
                keys.forEach(function (key) {
                    var currentValue = currentProfile[key] || [];
                    currentProfile[key] = Array.from(new Set(__spreadArray(__spreadArray([], currentValue, true), [styles[key]], false)));
                });
                state.result = __assign(__assign({}, result), { profile: currentProfile });
                state.stylesLoading = false;
            },
            prepare: function (resultId, styles) {
                return {
                    payload: {
                        resultId: resultId,
                        styles: styles,
                    },
                };
            },
        },
        updateStylesFailed: {
            reducer: function (state, action) {
                // revert styles when update failed
                var styles = action.payload.styles;
                var result = state.result;
                checkResult(result);
                var currentProfile = result.profile;
                var keys = Object.keys(styles);
                keys.forEach(function (key) {
                    var currentValue = currentProfile[key] || [];
                    var index = currentValue.indexOf(styles[key]);
                    currentValue.splice(index, 1);
                    currentProfile[key] = currentValue;
                });
                state.result = __assign(__assign({}, result), { profile: currentProfile });
                state.stylesError = action.payload;
                state.stylesLoading = false;
            },
            prepare: function (resultId, styles) {
                return {
                    payload: {
                        resultId: resultId,
                        styles: styles,
                    },
                };
            },
        },
        stylesUpdated: function (state) {
            state.stylesUpdated = true;
        },
        deleteStyles: {
            reducer: function (state, action) {
                state.stylesLoading = true;
                state.stylesUpdated = false;
            },
            prepare: function (resultId, styles) {
                return {
                    payload: {
                        resultId: resultId,
                        styles: styles,
                    },
                };
            },
        },
        deleteStylesSucceeded: {
            reducer: function (state, action) {
                var styles = action.payload.styles;
                var result = state.result;
                checkResult(result);
                var currentProfile = result.profile;
                var keys = Object.keys(styles);
                keys.forEach(function (key) {
                    var currentValue = currentProfile[key] || [];
                    var index = currentValue.indexOf(styles[key]);
                    currentValue.splice(index, 1);
                    currentProfile[key] = currentValue;
                });
                state.result = __assign(__assign({}, result), { profile: currentProfile });
                state.stylesLoading = false;
            },
            prepare: function (resultId, styles) {
                return {
                    payload: {
                        resultId: resultId,
                        styles: styles,
                    },
                };
            },
        },
        deleteStylesFailed: {
            reducer: function (state, action) {
                // if delete fails on backend we need to revert the changes on frontend
                var styles = action.payload.styles;
                var result = state.result;
                checkResult(result);
                var currentProfile = result.profile;
                var keys = Object.keys(styles);
                keys.forEach(function (key) {
                    var currentValue = currentProfile[key] || [];
                    currentProfile[key] = Array.from(new Set(__spreadArray(__spreadArray([], currentValue, true), [styles[key]], false)));
                });
                state.result = __assign(__assign({}, result), { profile: currentProfile });
            },
            prepare: function (resultId, styles) {
                return {
                    payload: {
                        resultId: resultId,
                        styles: styles,
                    },
                };
            },
        },
        overwriteStyles: {
            reducer: function (state, action) {
                state.overWriteStylesLoading = true;
            },
            prepare: function (resultId, styles) {
                return {
                    payload: {
                        resultId: resultId,
                        styles: styles,
                    },
                };
            },
        },
        overwriteStylesSucceeded: {
            reducer: function (state, action) {
                var styles = action.payload.styles;
                var result = state.result;
                checkResult(result);
                var currentProfile = result.profile;
                var keys = Object.keys(styles);
                keys.forEach(function (key) {
                    currentProfile[key] = styles[key];
                });
                state.overWriteStylesLoading = false;
                state.result = __assign(__assign({}, result), { profile: currentProfile });
            },
            prepare: function (resultId, styles) {
                return {
                    payload: {
                        resultId: resultId,
                        styles: styles,
                    },
                };
            },
        },
        overwriteStylesFailed: function (state, action) {
            state.overWriteStylesError = action.payload;
            state.overWriteStylesLoading = false;
        },
        loadGalleryImages: {
            reducer: function (state, action) { },
            prepare: function (searchProps) {
                return {
                    payload: {
                        searchProps: searchProps,
                    },
                };
            },
        },
        loadGalleryImagesSucceeded: {
            reducer: function (state, action) {
                var images = action.payload.images;
                var result = state.result;
                checkResult(result);
                state.result = __assign(__assign({}, result), { galleryImages: images });
            },
            prepare: function (images, resultId) {
                return {
                    payload: {
                        resultId: resultId,
                        images: images,
                    },
                };
            },
        },
        loadGalleryImagesFailed: function (state, action) {
            state.error = action.payload;
        },
        loadProducts: {
            reducer: function (state, action) { },
            prepare: function (searchProps) {
                return {
                    payload: {
                        searchProps: searchProps,
                    },
                };
            },
        },
        loadProductsSucceeded: {
            reducer: function (state, action) {
                var products = action.payload.products;
                var result = state.result;
                checkResult(result);
                var previousProducts = result.products || [];
                var newProducts = products;
                if (isEmpty(newProducts)) {
                    state.result = __assign(__assign({}, result), { products: previousProducts, noMoreProducts: true, loadingProducts: false });
                }
                var uniqueProducts = newProducts.filter(function (newProduct) {
                    return !previousProducts.find(function (previousProduct) { return previousProduct.Product.id === newProduct.Product.id; });
                });
                state.result = __assign(__assign({}, result), { products: __spreadArray(__spreadArray([], previousProducts, true), uniqueProducts, true), loadingProducts: false });
            },
            prepare: function (products, resultId) {
                return {
                    payload: {
                        resultId: resultId,
                        products: products,
                    },
                };
            },
        },
        loadProductsFailed: function (state, action) {
            state.error = action.payload;
        },
        loadResultLikes: function (state, action) { },
        loadResultLikesSucceeded: {
            reducer: function (state, action) {
                var resultLikes = action.payload.resultLikes;
                var result = state.result;
                checkResult(result);
                state.result = __assign(__assign({}, result), { likes: resultLikes });
            },
            prepare: function (resultLikes, resultId) {
                return {
                    payload: {
                        resultId: resultId,
                        resultLikes: resultLikes,
                    },
                };
            },
        },
        loadResultLikesFailed: function (state, action) {
            state.error = action.payload;
        },
        resultMessageSent: function (state, action) {
            var message = action.payload;
            sendToHost.publish(MESSAGETYPES.SEND_LOADING_MESSAGE, {
                message: message,
            });
        },
        sendForm: {
            reducer: function (state, action) {
                state.formLoading = true;
            },
            prepare: function (endpoint, formData, options) {
                return {
                    payload: {
                        endpoint: endpoint,
                        formData: formData,
                        options: options
                    },
                };
            },
        },
        sendFormSucceeded: function (state, action) {
            state.formResult = __assign({}, action.payload);
            state.formLoading = false;
        },
        sendFormFailed: function (state, action) {
            state.formError = action.payload;
            state.formLoading = false;
        },
        doLike: {
            reducer: function (state, action) {
                var _a;
                var result = state.result;
                checkResult(result);
                state.result = __assign(__assign({}, result), { likeLoading: __assign(__assign({}, result.likeLoading), (_a = {}, _a[action.payload.itemId] = true, _a)) });
            },
            prepare: function (payload) {
                var like = payload.like, itemType = payload.itemType, itemId = payload.itemId;
                return {
                    payload: {
                        like: like,
                        itemType: itemType,
                        itemId: itemId,
                    },
                };
            },
        },
        doLikeSucceeded: function (state, action) {
            var _a;
            state.formError = action.payload.response;
            state.formLoading = false;
            state.result.likeLoading = __assign(__assign({}, state.result.likeLoading), (_a = {}, _a[action.payload.itemId] = false, _a));
        },
        doLikeFailed: function (state, action) {
            var _a;
            state.error = action.payload;
            state.result.likeLoading = __assign(__assign({}, state.result.likeLoading), (_a = {}, _a[action.payload.itemId] = false, _a));
        },
        sendFormToUrl: {
            reducer: function (state, action) {
                state.formLoading = true;
            },
            prepare: function (url, processId) {
                return {
                    payload: {
                        url: url,
                        processId: processId,
                    },
                };
            },
        },
        sendFormToUrlSucceeded: function (state, action) {
            state.formLoading = false;
        },
        sendFormToUrlFailed: function (state, action) {
            state.formLoading = false;
            state.error = action.payload;
        },
        sendFeedback: {
            reducer: function (state, action) { },
            prepare: function (payload) {
                var rating = payload.rating, comment = payload.comment, resultId = payload.resultId;
                return {
                    payload: {
                        rating: rating,
                        comment: comment,
                        resultId: resultId,
                    },
                };
            },
        },
        storeResultMessage: function (state, action) {
            var message = action.payload;
            sendToHost.publish(MESSAGETYPES.SEND_LOADING_MESSAGE, {
                message: message,
            });
        },
        loadResultCalendarLinks: {
            reducer: function (state, action) {
            },
            prepare: function (hash) {
                return {
                    payload: {
                        hash: hash,
                    },
                };
            },
        },
        loadCalendarLinksSucceeded: function (state, action) {
            state.calendarLinks = action.payload;
        },
        loadCalendarLinksFailed: function (state, action) {
            state.calendarLinks = {};
        }
    },
});
export var loadResult = (_a = resultsSlice.actions, _a.loadResult), loadResultSucceeded = _a.loadResultSucceeded, loadResultFailed = _a.loadResultFailed, updateStyles = _a.updateStyles, updateStylesSucceeded = _a.updateStylesSucceeded, updateStylesFailed = _a.updateStylesFailed, deleteStyles = _a.deleteStyles, deleteStylesSucceeded = _a.deleteStylesSucceeded, deleteStylesFailed = _a.deleteStylesFailed, overwriteStyles = _a.overwriteStyles, overwriteStylesSucceeded = _a.overwriteStylesSucceeded, overwriteStylesFailed = _a.overwriteStylesFailed, loadGalleryImages = _a.loadGalleryImages, loadGalleryImagesSucceeded = _a.loadGalleryImagesSucceeded, loadGalleryImagesFailed = _a.loadGalleryImagesFailed, loadProducts = _a.loadProducts, loadProductsSucceeded = _a.loadProductsSucceeded, loadProductsFailed = _a.loadProductsFailed, loadResultLikes = _a.loadResultLikes, loadResultLikesSucceeded = _a.loadResultLikesSucceeded, loadResultLikesFailed = _a.loadResultLikesFailed, resultMessageSent = _a.resultMessageSent, sendForm = _a.sendForm, sendFormSucceeded = _a.sendFormSucceeded, sendFormFailed = _a.sendFormFailed, openResult = _a.openResult, sendAnswers = _a.sendAnswers, sendAnswersFailed = _a.sendAnswersFailed, sendAnswersSucceeded = _a.sendAnswersSucceeded, openCurrentResult = _a.openCurrentResult, doLike = _a.doLike, doLikeSucceeded = _a.doLikeSucceeded, doLikeFailed = _a.doLikeFailed, sendFormToUrl = _a.sendFormToUrl, sendFormToUrlSucceeded = _a.sendFormToUrlSucceeded, sendFormToUrlFailed = _a.sendFormToUrlFailed, sendFeedback = _a.sendFeedback, storeResultMessage = _a.storeResultMessage, stylesUpdated = _a.stylesUpdated, loadResultCalendarLinks = _a.loadResultCalendarLinks, loadCalendarLinksFailed = _a.loadCalendarLinksFailed, loadCalendarLinksSucceeded = _a.loadCalendarLinksSucceeded;
export default resultsSlice.reducer;
