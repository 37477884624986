var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import pick from 'lodash/pick';
import 'idempotent-babel-polyfill';
import { createStore } from 'conversional-journey';
import { urlHelpers } from 'conversional-api';
import envConfig from 'conversional-environment';
import { sendToHost, parseMessage, MESSAGETYPES } from 'conversional-iframe-communication';
import { createTheme, insertGlobalStyles } from 'conversional-theme';
import get from 'lodash/get';
import { setItem } from 'conversional-persistent-storage';
var TIME_TO_LIVE = 86400000 * 4; // 1000ms * 60s * 24hours
// extract startup relevant data from snippet
var params = urlHelpers.parseQueryString();
var snippetKeys = ['api', 'version', 'key', 'journey', 'token', 'iframeId', 'result'];
var snippetSettings = pick(params, snippetKeys);
window.conversional = {
    origin: decodeURIComponent(params.origin),
};
snippetSettings['baseUrl'] = snippetSettings['api']
    ? decodeURIComponent(snippetSettings['api'])
    : '';
snippetSettings['apiKey'] = snippetSettings['key'] || '';
snippetSettings['journeyId'] = snippetSettings['journey'] || '';
snippetSettings['resultId'] = snippetSettings['result'] || '';
delete snippetSettings['api'];
delete snippetSettings['key'];
delete snippetSettings['journey'];
delete snippetSettings['result'];
var settings = __assign(__assign({}, envConfig), snippetSettings);
function renderIframeContent(_a) {
    var journey = _a.journey, iframe = __rest(_a, ["journey"]);
    if (journey.theme)
        journey.theme = createTheme(journey.theme);
    journey.iframe = iframe;
    // Temporary solution to set inital abtest value because abTest key is acquired through initial journey config API call
    var initialAbTest = get(iframe, 'viewConfig.abTest', null);
    if (initialAbTest)
        setItem('abTest', initialAbTest, { ttl: TIME_TO_LIVE });
    createStore(settings, journey).then(function (store) {
        var theme = store.getState().journeys.journeys[0].theme || journey.theme;
        insertGlobalStyles(theme);
        import('./utils/render').then(function (module) {
            module.default(store, journey);
        });
    });
}
// Content cannot be passed directly to the iframe
// Therefore the iframe needs to set up a listener and wait for the host
// to send content
var onContent = function (message) {
    var _a = parseMessage(message), type = _a.type, payload = _a.payload;
    if (type === MESSAGETYPES.SET_CONTENT) {
        if (!payload.journey.theme) {
            payload.journey.theme = {};
        }
        window.customComponentsMap = payload.journey.components;
        window.parentWidth = payload.windowWidth;
        window.removeEventListener('message', onContent);
        renderIframeContent(payload);
    }
};
window.addEventListener('message', onContent);
sendToHost.ready(params === null || params === void 0 ? void 0 : params.iframeId);
