var _a;
import { createSlice } from "@reduxjs/toolkit";
var initialState = {
    loading: {},
    uploadedImages: [],
    response: {},
    error: {},
    selectedAppointmentType: null,
    selectedAppointmentLocation: null,
    selectedAppointmentSlot: {
        "start": null,
        "end": null,
        "resource_id": null
    },
};
var appointmentBookingSlice = createSlice({
    name: "appointmentBooking",
    initialState: initialState,
    reducers: {
        sendImages: function (state, action) {
            var fileName = action.payload.fileName;
            state.loading[fileName] = true;
            state.response[fileName] = undefined;
            state.error[fileName] = undefined;
        },
        sendImagesSucceeded: function (state, action) {
            var _a = action.payload, image = _a.image, response = _a.response;
            var file = image.file;
            state.loading[file.name] = false;
            state.response[file.name] = response;
            state.uploadedImages.push({
                dataURL: image.dataURL,
                file: {
                    name: file.name,
                    type: file.type,
                    size: file.size
                }
            });
        },
        sendImagesFailed: function (state, action) {
            var _a = action.payload, file = _a.image.file, error = _a.error;
            state.loading[file.name] = false;
            state.error[file.name] = error;
        },
        changeSelectedLocation: function (state, action) {
            state.selectedAppointmentLocation = action.payload;
            state.selectedAppointmentSlot = {
                "start": null,
                "end": null,
                "resource_id": null
            };
        },
        setSelectedSlot: function (state, action) {
            state.selectedAppointmentSlot = action.payload;
        },
        changeSelectedAppointmentType: function (state, action) {
            state.selectedAppointmentType = action.payload;
            state.selectedAppointmentLocation = null;
            state.selectedAppointmentSlot = {
                "start": null,
                "end": null,
                "resource_id": null
            };
        }
    }
});
export var sendImages = (_a = appointmentBookingSlice.actions, _a.sendImages), sendImagesSucceeded = _a.sendImagesSucceeded, sendImagesFailed = _a.sendImagesFailed, changeSelectedLocation = _a.changeSelectedLocation, setSelectedSlot = _a.setSelectedSlot, changeSelectedAppointmentType = _a.changeSelectedAppointmentType;
export default appointmentBookingSlice.reducer;
