function appendOrReplace(elements, newElement, identifier) {
    if (identifier === void 0) { identifier = 'id'; }
    if (!Array.isArray(elements))
        return [];
    var isInArray = elements.filter(function (elem) {
        return elem[identifier] === newElement[identifier];
    }).length;
    if (isInArray === 0) {
        elements.push(newElement);
        return elements;
    }
    return elements.map(function (elem) {
        if (elem[identifier] === newElement[identifier]) {
            return newElement;
        }
        return elem;
    });
}
export default appendOrReplace;
