import * as stringUtils from "./stringUtils";
var SELF_DOMAINS = ['roomtailors.de', 'conversional.de', 'conversion.al'];
var GOOGLE_STORAGE_URL = 'storage.googleapis.com/conversional_';
// @ts-ignore
var APP_ENV = process.env.REACT_APP_JOURNEY_ENV;
export var CLOUD_IMAGE_URL_DOMAIN = "https://image-resizer.conversional.de/";
export function getDomainEnvironmentForImage(imageSource) {
    var rootDomain = SELF_DOMAINS.find(function (domain) { return imageSource.includes(domain); });
    if (rootDomain) {
        var urlParts = imageSource.split('//');
        var domainPart = urlParts[1].split('/')[0];
        var env = domainPart.split(rootDomain)[0];
        return env === "" ? "production" : env.split(".")[0];
    }
    if (imageSource.includes(GOOGLE_STORAGE_URL)) {
        var parts = imageSource.split(GOOGLE_STORAGE_URL);
        return parts[1].split("/")[0];
    }
    return null;
}
export function isImageInConversionalDomain(imageSource) {
    try {
        if (imageSource.includes(GOOGLE_STORAGE_URL))
            return true;
        var urlParts = imageSource.split('//');
        var domainPart_1 = urlParts[1].split('/')[0];
        return SELF_DOMAINS.some(function (domain) { return domainPart_1.includes(domain); });
    }
    catch (e) {
        // Do not break in case it is not recognizable
        return false;
    }
}
export var getCloudResponsiveImageUrl = function (fileUrl, domainEnvironment, env) {
    var buckets = {
        "production": "production",
        "development": "production",
        "staging": "staging",
        "testing": "testing",
        "default": "production",
    };
    var pictureEnvironment = domainEnvironment ? buckets[domainEnvironment] : (buckets[env] || buckets['default']);
    if (fileUrl.startsWith("conversional_"))
        fileUrl = fileUrl.split("/").slice(1).join("/");
    if (fileUrl.endsWith(".svg"))
        return "https://storage.googleapis.com/conversional_".concat(pictureEnvironment, "/").concat(fileUrl);
    return "".concat(CLOUD_IMAGE_URL_DOMAIN, "image-url?bucket=conversional_").concat(pictureEnvironment, "&image=").concat(fileUrl);
};
/**
 * The breakpoint will be added before the file name in image path.
 * This is the link:
 * https://conversional-cloud.ey.r.appspot.com/image-url?bucket=conversional_production&image=files/filestore/file/1000/KuCB34z27grauklassisch.jpg
 * This is the response sample:
 * {"image_url": "http://lh3.googleusercontent.com/4G-Hcjzvdbd4pbA2wkI2Cwlw7G5N2hnPV1jgLyylEdcnRyTFStHAjJUlJ3ww_k1Pt0UnNSw1j9G-5XUTZnEVC3p2XWLsgkYH-bZP_36hw3-ieg"}
 * To create responsive images:
 * http://lh3.googleusercontent.com/4G-Hcjzvdbd4pbA2wkI2Cwlw7G5N2hnPV1jgLyylEdcnRyTFStHAjJUlJ3ww_k1Pt0UnNSw1j9G-5XUTZnEVC3p2XWLsgkYH-bZP_36hw3-ieg=s${widthInPixesl}
 * For example if we want to have an image with 100 with:
 * http://lh3.googleusercontent.com/4G-Hcjzvdbd4pbA2wkI2Cwlw7G5N2hnPV1jgLyylEdcnRyTFStHAjJUlJ3ww_k1Pt0UnNSw1j9G-5XUTZnEVC3p2XWLsgkYH-bZP_36hw3-ieg=s100
 * */
export function generateMultiResolutionSrc(_a) {
    var imageSource = _a.imageSource, width = _a.width, aspectRatio = _a.aspectRatio, _b = _a.env, env = _b === void 0 ? APP_ENV : _b;
    if (!isImageInConversionalDomain(imageSource))
        return imageSource;
    width = Math.round(width);
    if (width === 0)
        return '';
    var isSvg = imageSource.endsWith('svg');
    var thirdSlashIndex = stringUtils.getPosition(imageSource, "/", 3);
    var domainEnvironment = getDomainEnvironmentForImage(imageSource);
    var fileUrl = imageSource.slice(thirdSlashIndex + 1) + (isSvg ? "" : "&w=" + width);
    if (typeof aspectRatio === "string") {
        var aspectRatioH = parseInt(aspectRatio.split(":")[0]);
        var aspectRatioW = parseInt(aspectRatio.split(":")[1]);
        if (!isNaN(aspectRatioH) && !isNaN(aspectRatioW)) {
            var height = Math.round(width * aspectRatioW / aspectRatioH);
            fileUrl += "&h=" + height;
        }
    }
    return getCloudResponsiveImageUrl(fileUrl, domainEnvironment, env);
}
