var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from "@reduxjs/toolkit";
import merge from 'lodash/merge';
var initialState = {
    external: {
        data: null
    },
    config: {}
};
var configSlice = createSlice({
    name: "common",
    initialState: initialState,
    reducers: {
        setConfig: function (state, action) {
            state = __assign(__assign({}, state), action.payload);
        },
        setExternal: function (state, action) {
            state.external = merge(state.external, action.payload);
        },
        mergeState: function (state, action) {
        }
    },
});
export var setConfig = (_a = configSlice.actions, _a.setConfig), setExternal = _a.setExternal, mergeState = _a.mergeState;
export default configSlice.reducer;
