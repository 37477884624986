var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
import set from 'lodash/set';
import merge from 'lodash/merge';
import get from "lodash/get";
var INITIAL_PROCESS_DATA = {
    step: 1,
    data: {},
    id: null,
    pathName: 'Main'
};
/**
 * Returns a process from the processes array
 * Will create a process if id was not found
 */
function getProcess(processes, processId) {
    var process = processes.find(function (process) { return process.id === processId; });
    if (!process) {
        return __assign(__assign({}, INITIAL_PROCESS_DATA), { id: processId });
    }
    return __assign({}, process);
}
/**
 * Replaces an existing process with the provided one
 * Replacement is decided by matching IDs
 */
function replaceProcess(processes, updatedProcess) {
    if (!processes)
        return [updatedProcess];
    var currentProcesses = processes.filter(function (process) { return process.id !== updatedProcess.id; });
    return currentProcesses.concat(updatedProcess);
}
var initialState = {
    processes: [],
};
var processesSlice = createSlice({
    name: 'processes',
    initialState: initialState,
    reducers: {
        nextStep: function (state, action) {
            //   const state = cloneDeep(state) ==> why?
            var currentProcess = getProcess(state.processes, action.payload.processId);
            if (!currentProcess)
                return state;
            var keys = Object.keys(action.payload.input);
            var addedData = {};
            keys.forEach(function (key) {
                set(addedData, key, action.payload.input[key]);
            });
            var mergedData = merge(currentProcess.data, addedData);
            var newProcess = __assign(__assign({}, currentProcess), { id: action.payload.processId, data: mergedData, stepName: "" });
            var targetStep = action.payload.step
                ? currentProcess.step + action.payload.step
                : currentProcess.step + 1;
            var step = currentProcess.step ? targetStep : 2;
            newProcess.step = step;
            newProcess.stepName = get(currentProcess, "path.".concat(step - 1), "");
            state.processes = replaceProcess(state.processes, newProcess);
        },
        storeStepData: function (state, action) {
            var currentProcess = getProcess(state.processes, action.payload.processId);
            if (!currentProcess)
                return state;
            var keys = Object.keys(action.payload.input);
            var addedData = {};
            keys.forEach(function (key) {
                set(addedData, key, action.payload.input[key]);
            });
            var mergedData = merge(currentProcess.data, addedData);
            var newProcess = __assign(__assign({}, currentProcess), { id: action.payload.processId, data: mergedData });
            state.processes = replaceProcess(state.processes, newProcess);
        },
        previousStep: function (state, action) {
            var currentProcess = getProcess(state.processes, action.payload.processId);
            if (!currentProcess)
                return state;
            var targetStep = action.payload.step
                ? currentProcess.step - action.payload.step
                : currentProcess.step - 1;
            var step = currentProcess.step ? targetStep : 1;
            var newProcess = __assign(__assign({}, currentProcess), { stepName: get(currentProcess, "path.".concat(step - 1), ""), step: step });
            state.processes = replaceProcess(state.processes, newProcess);
        },
        goToStep: function (state, action) {
            var currentProcess = getProcess(state.processes, action.payload.processId);
            if (!currentProcess)
                return state;
            var targetStep = action.payload.step ? action.payload.step : currentProcess.step;
            var newProcess = __assign(__assign({}, currentProcess), { step: targetStep, stepName: get(currentProcess, "path.".concat(targetStep - 1), ""), data: currentProcess.data });
            state.processes = replaceProcess(state.processes, newProcess);
        },
        setPath: function (state, action) {
            var newProcess = __assign(__assign({}, getProcess(state.processes, action.payload.processId)), { path: action.payload.path });
            state.processes = replaceProcess(state.processes, newProcess);
        },
        setPathName: function (state, action) {
            var currentProcess = getProcess(__spreadArray([], state.processes, true), action.payload.processId);
            if (!currentProcess)
                return state;
            var newProcess = __assign(__assign({}, getProcess(state.processes, action.payload.processId)), { pathName: action.payload.pathName });
            state.processes = replaceProcess(state.processes, newProcess);
        },
        completed: function (state, action) {
        },
        addData: function (state, action) {
            var currentProcess = getProcess(__spreadArray([], state.processes, true), action.payload.processId);
            if (!currentProcess)
                return state;
            var keys = Object.keys(action.payload.data);
            var addedData = {};
            keys.forEach(function (key) {
                set(addedData, key, action.payload.data[key]);
            });
            var mergedData = merge(currentProcess.data, addedData);
            var newProcess = __assign(__assign({}, currentProcess), { id: action.payload.processId, data: mergedData });
            state.processes = replaceProcess(state.processes, newProcess);
        },
        reset: function (state) {
            state.processes = [];
        },
        restartProcess: function (state, action) {
            var currentProcess = getProcess(__spreadArray([], state.processes, true), action.payload.processId);
            if (!currentProcess)
                return state;
            var newProcess = __assign(__assign({}, INITIAL_PROCESS_DATA), { id: action.payload.processId });
            state.processes = replaceProcess(state.processes, newProcess);
        }
    },
});
export var addData = (_a = processesSlice.actions, _a.addData), completed = _a.completed, goToStep = _a.goToStep, nextStep = _a.nextStep, previousStep = _a.previousStep, reset = _a.reset, restartProcess = _a.restartProcess, setPath = _a.setPath, setPathName = _a.setPathName, storeStepData = _a.storeStepData;
export default processesSlice.reducer;
