var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import get from "lodash/get";
import { GeneralStyles, DesktopStyles, MobileStyles, TabletStyles } from '../classes/classes';
export var getComponentCutomizedStyles = function (injectedStyles) {
    if (injectedStyles === void 0) { injectedStyles = {}; }
    var general = injectedStyles.general, mobile = injectedStyles.mobile, desktop = injectedStyles.desktop, tablet = injectedStyles.tablet, direct = __rest(injectedStyles, ["general", "mobile", "desktop", "tablet"]);
    return __assign(__assign(__assign(__assign(__assign({}, direct), GeneralStyles(general)), DesktopStyles(desktop)), TabletStyles(tablet)), MobileStyles(mobile));
};
export var getComponentCustomizedStylesByType = function (componentStyles, cssProp, defaultValue, sybType) {
    if (componentStyles === void 0) { componentStyles = {}; }
    if (sybType === void 0) { sybType = "general"; }
    if (!componentStyles)
        return {};
    var styles = componentStyles[sybType];
    if (!styles) {
        return get(getComponentCutomizedStyles(componentStyles === null || componentStyles === void 0 ? void 0 : componentStyles.general), cssProp, defaultValue);
    }
    return get(getComponentCutomizedStyles(styles), cssProp, defaultValue);
};
