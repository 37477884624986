import { createSelector } from '@reduxjs/toolkit';
var selectAppointmentBookingState = function (state) { return state.appointmentBooking; };
export var selectAppointmentBookingLoading = createSelector(selectAppointmentBookingState, function (appointmentBooking) { return appointmentBooking.loading; });
export var selectAppointmentBookingResponse = createSelector(selectAppointmentBookingState, function (appointmentBooking) { return appointmentBooking.response; });
export var selectAppointmentBookingError = createSelector(selectAppointmentBookingState, function (appointmentBooking) { return appointmentBooking.error; });
export var selectAppointmentBookingUploadedImages = createSelector(selectAppointmentBookingState, function (appointmentBooking) { return appointmentBooking.uploadedImages; });
export var selectAppointmentBooking = createSelector(selectAppointmentBookingState, function (appointmentBooking) { return appointmentBooking; });
export var selectAppointmentBookingPickerState = createSelector(selectAppointmentBookingState, function (appointmentBooking) { return ({
    selectedAppointmentLocation: appointmentBooking.selectedAppointmentLocation,
    selectedAppointmentSlot: appointmentBooking.selectedAppointmentSlot,
    selectedAppointmentType: appointmentBooking.selectedAppointmentType
}); });
