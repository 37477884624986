var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { putResolve, select, takeLatest } from 'redux-saga/effects';
import { getLocationsApi, getLocationsMetaApi } from 'conversional-api';
import * as sagaUtils from '../../../helper/sagaUtils';
import * as Actions from '../locationsSlice';
import { currentJourneyId } from '../../journeys/selectors';
var requestNumber = 0;
function getLocations(action) {
    var _a, closeTo, locationType, max_result, with_details, lat, lng, include, filters, max_distance, selectedJourneyId, payload, response, error_1;
    var _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 5, , 7]);
                _a = action.payload, closeTo = _a.closeTo, locationType = _a.locationType, max_result = _a.max_result, with_details = _a.with_details, lat = _a.lat, lng = _a.lng, include = _a.include, filters = _a.filters, max_distance = _a.max_distance;
                return [4 /*yield*/, select(currentJourneyId)];
            case 1:
                selectedJourneyId = _e.sent();
                payload = {
                    close_to: closeTo,
                    journeyId: selectedJourneyId,
                    locationType: locationType,
                    req_no: 0,
                    max_distance: max_distance,
                    lat: lat,
                    lng: lng,
                    max_result: max_result,
                    with_details: with_details,
                    include: include,
                    filters: filters
                };
                if (with_details) {
                    payload.req_no = requestNumber++;
                }
                return [4 /*yield*/, sagaUtils.api(getLocationsApi(payload))];
            case 2:
                response = _e.sent();
                if (!response) return [3 /*break*/, 4];
                return [4 /*yield*/, putResolve(Actions.getLocationsSucceeded(Array.isArray(response) ? response : response.data))];
            case 3:
                _e.sent();
                _e.label = 4;
            case 4: return [3 /*break*/, 7];
            case 5:
                error_1 = _e.sent();
                return [4 /*yield*/, putResolve(Actions.getLocationsFailed(((_d = (_c = (_b = error_1 === null || error_1 === void 0 ? void 0 : error_1.response) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.error) === null || _d === void 0 ? void 0 : _d.message) || error_1.message))];
            case 6:
                _e.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getLocationMeta(action) {
    var response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 8]);
                return [4 /*yield*/, sagaUtils.api(getLocationsMetaApi(action.payload))];
            case 1:
                response = _a.sent();
                if (!response) return [3 /*break*/, 3];
                return [4 /*yield*/, putResolve(Actions.getLocationMetaSucceeded(response))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3: return [4 /*yield*/, putResolve(Actions.getLocationMetaFailed('No response'))];
            case 4:
                _a.sent();
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                error_2 = _a.sent();
                return [4 /*yield*/, putResolve(Actions.getLocationMetaFailed(error_2.message))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
export default function actionWatcher() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(Actions.getLocations.type, getLocations)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(Actions.getLocationMeta.type, getLocationMeta)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
