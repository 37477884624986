export function shadeColor(color, percent) {
    if (!color)
        return;
    var f = parseInt(color.slice(1), 16), t = percent < 0 ? 0 : 255, p = percent < 0 ? percent * -1 : percent, R = f >> 16, G = (f >> 8) & 0x00ff, B = f & 0x0000ff;
    return ('#' +
        (0x1000000 +
            (Math.round((t - R) * p) + R) * 0x10000 +
            (Math.round((t - G) * p) + G) * 0x100 +
            (Math.round((t - B) * p) + B))
            .toString(16)
            .slice(1));
}
/**
 * Changes Color Brightness. If Percent < 0 it Darkens the Color.
 * @param {string} color - Color (Hex Format).
 * @param {number} percent - Brightness (-100 to 100).
 * @returns {string} newColor - New color in Hex Format
 */
export function brightenColor(color, percent) {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);
    R = Number((R * (100 + percent)) / 100);
    G = Number((G * (100 + percent)) / 100);
    B = Number((B * (100 + percent)) / 100);
    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;
    var RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);
    return '#' + RR + GG + BB;
}
/**
 * Changes Color's Opacity.
 * @param {string} color - Color (Hex Format).
 * @param {number} opacity - Opacity (0 to 100).
 * @returns {string} newColor - New color in RGBA format
 */
export function setColorOpacity(color, opacity) {
    var R = parseInt(color.length === 4 ? color.substring(1, 2) : color.substring(1, 3), 16);
    var G = parseInt(color.length === 4 ? color.substring(2, 3) : color.substring(3, 5), 16);
    var B = parseInt(color.length === 4 ? color.substring(3, 4) : color.substring(5, 7), 16);
    return "rgba(".concat(R, ", ").concat(G, ", ").concat(B, ", ").concat(opacity / 100, ")");
}
