var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import * as PubSub from 'pubsub-js';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import get from 'lodash/get';
import Events from './GlobalEvents';
import urlEventsHandler from './urlEventsHandler';
export var unsubscribeAll = PubSub.clearAllSubscriptions;
export var GlobalEvents = Events;
// This function can be used to unsubscribe functional Components
// TODO: Refactor by making the whole subscription and unsubscription part of a hook
export function unsubscribeFunctionalComponent(callbacks, callbackMap) {
    if (callbacks === void 0) { callbacks = []; }
    for (var key in callbacks) {
        PubSub.unsubscribe(callbackMap["".concat(callbacks[key].trigger, "Callback")]);
    }
}
export function removeCallbacks(component, callbacks, callbackMap) {
    if (component === void 0) { component = {}; }
    if (callbacks === void 0) { callbacks = {}; }
    _removeSubscribers(component, callbacks, callbackMap);
}
export function addCallbacks(component, callbacks, callbackMap) {
    if (component === void 0) { component = {}; }
    if (!callbacks) {
        return;
    }
    // Add all subscribers - requires callbackMap due to limited ability to reference functions with strings in ES6 Classes
    component._subscriptions = component._subscriptions || [];
    var tokens = _addSubscribers(component, callbacks, callbackMap);
    // Automated remove on unmount to avoid repetitive removal of subscribers
    _removeSubscribers(component, callbacks, callbackMap);
    return tokens;
}
export function unsubscribeCallbacksByTokens(tokens) {
    if (tokens === void 0) { tokens = []; }
    (Array.isArray(tokens) ? tokens : [tokens]).forEach(function (token) { return PubSub.unsubscribe(token); });
}
export function publish(event, payload, senderId) {
    if (payload === void 0) { payload = {}; }
    if (senderId === void 0) { senderId = false; }
    var eventName = senderId ? "".concat(event, ".").concat(senderId) : event;
    PubSub.publish(eventName, payload);
    log('Event published', eventName, payload);
}
var environment = process.env.REACT_APP_JOURNEY_ENV || 'development';
function log(message) {
    var props = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        props[_i - 1] = arguments[_i];
    }
    if (environment === 'development' || environment === 'testing') {
        // eslint-disable-next-line no-console
        console.debug.apply(console, __spreadArray(['%c Callbacks: ' + message, 'color: blueViolet'], props, false));
    }
}
/**
 *
 * @param component
 * @param callbacks
 * @param callbackMap
 * @private
 *
 * Supported callback properties:
 * on: which function is called
 * delay: how much time is passed before calling it
 * pick: variable to extract from payload
 */
function _addSubscribers(component, callbacks, callbackMap) {
    var subscriberTokens = [];
    // eslint-disable-next-line no-unused-vars
    for (var key in callbacks) {
        var trigger = callbackMap["".concat(callbacks[key].trigger, "Callback")];
        var callback = _buildCallbackFunction(callbacks[key], trigger);
        var event = callbacks[key].on;
        if (callback) {
            var token = _addCallback(component, event, callback);
            subscriberTokens.push(token);
            log('Subscriber added', event, callbacks[key], trigger);
        }
    }
    return subscriberTokens;
}
/**
 * Modifies callback function by adding additional configuration behavior, e.g. delaying the callback or modifying payload before passing paramters.
 * @private
 */
function _buildCallbackFunction(configuration, callback) {
    var on = configuration.on, pick = configuration.pick, delay = configuration.delay, addPayload = configuration.addPayload, payloadCondition = configuration.payloadCondition;
    var modifiedCallback = callback;
    if (!on || !callback) {
        return;
    }
    if (pick) {
        modifiedCallback = _wrapInPick(modifiedCallback, pick);
    }
    if (delay) {
        modifiedCallback = _wrapInTimeout(modifiedCallback, delay);
    }
    // Will add a static value, e.g. a required prop
    if (addPayload) {
        modifiedCallback = _wrapInAddPayload(modifiedCallback, addPayload);
    }
    // Payload condition will only trigger the callback if the payload is equal to the condition
    if (payloadCondition) {
        modifiedCallback = _wrapInPayloadCondition(modifiedCallback, payloadCondition);
    }
    return modifiedCallback;
}
function _wrapInAddPayload(callback, addPayload) {
    return function (on, payload) {
        if (payload === void 0) { payload = {}; }
        payload = __assign(__assign({}, payload), addPayload);
        return callback(on, payload);
    };
}
function _wrapInPayloadCondition(callback, payloadCondition) {
    return function (on, payload) {
        if (payload === void 0) { payload = {}; }
        var checkValuesFor = Object.keys(payloadCondition);
        checkValuesFor.forEach(function (key) {
            var value = get(payload, key);
            var expected = payloadCondition[key];
            if (isEqual(value, expected)) {
                callback(on, payload);
            }
        });
    };
}
function _wrapInPick(callback, pickKeys) {
    return function (on, payload) {
        if (payload === void 0) { payload = {}; }
        payload = pick(payload, pickKeys);
        callback(on, payload);
    };
}
function _wrapInTimeout(callback, delay) {
    return function (on, payload) {
        if (payload === void 0) { payload = {}; }
        setTimeout(function () {
            callback(on, payload);
        }, delay);
    };
}
function _removeSubscribers(component, callbacks, callbackMap) {
    // Only auto-remove subscribers in class components with lifecycle functions
    if (!component.componentWillUnmount) {
        return;
    }
    component.componentWillUnmount = (function (wrapped) {
        function extendsInit() {
            // eslint-disable-next-line no-unused-vars
            for (var key in callbacks) {
                PubSub.unsubscribe(callbackMap["".concat(callbacks[key].trigger, "Callback")]);
            }
            if (typeof wrapped === 'function') {
                wrapped();
            }
        }
        return extendsInit;
    })(component.componentWillUnmount);
}
/**
 * Attaches a subscriber to trigger CALLBACK in case of EVENT.
 * Returns a token to be used for unsubscribing.
 *
 * @param _component
 * @param event
 * @param callback
 * @returns {*}
 * @private
 */
function _addCallback(_component, event, callback) {
    if (!callback) {
        return;
    }
    return PubSub.subscribe(event, callback);
}
var callbacks = {
    addCallbacks: addCallbacks,
    publish: publish,
    removeCallbacks: removeCallbacks,
    urlEventsHandler: urlEventsHandler,
    unsubscribeCallbacksByTokens: unsubscribeCallbacksByTokens
};
// const clearAllSubscriptions = PubSub.clearAllSubscriptions
// export clearAllSubscriptions
export default callbacks;
