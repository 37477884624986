import { getVendorLocationApi } from 'conversional-api';
import { rtkApi } from '../../api/rtkApi';
import qs from 'qs';
var vendorLocationApi = rtkApi
    .enhanceEndpoints({
    addTagTypes: ['VendorLocation'],
})
    .injectEndpoints({
    endpoints: function (build) {
        return {
            getVendorLocation: build.query({
                query: function (payload) {
                    var path = getVendorLocationApi({ projectId: payload }).path;
                    return {
                        url: path,
                        method: 'GET',
                        paramsSerializer: function (params) {
                            return qs.stringify(params, { arrayFormat: 'brackets' });
                        },
                    };
                },
            }),
        };
    },
});
export var useGetVendorLocationQuery = vendorLocationApi.useGetVendorLocationQuery;
export default vendorLocationApi;
