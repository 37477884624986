import { useEffect } from 'react';
import { addCallbacks, unsubscribeFunctionalComponent } from 'conversional-callbacks';
export function useCallbacks(component, registeredCallbacks, supportedCallbacks) {
    useEffect(function () {
        addCallbacks(component, registeredCallbacks || [], supportedCallbacks);
        return function () {
            unsubscribeFunctionalComponent(registeredCallbacks, supportedCallbacks);
        };
    }, [JSON.stringify(registeredCallbacks)]);
}
