import { getContentApi } from 'conversional-api';
import { rtkApi } from '../../api/rtkApi';
import qs from 'qs';
import * as JourneysSelectors from "../journeys/selectors";
import { objectUtil } from "conversional-utils";
var contentApi = rtkApi
    .enhanceEndpoints({
    addTagTypes: ['Content'],
})
    .injectEndpoints({
    endpoints: function (build) {
        return {
            loadContent: build.query({
                query: function (_a) {
                    var contentType = _a.contentType, contentParams = _a.contentParams;
                    var _b = getContentApi(contentType, contentParams), path = _b.path, query = _b.query;
                    return {
                        url: path,
                        method: 'GET',
                        params: query,
                        paramsSerializer: function (params) {
                            return qs.stringify(params, { arrayFormat: 'brackets' });
                        },
                    };
                },
                transformResponse: function (response, meta, arg) {
                    var journey = JourneysSelectors.currentJourney(window.reduxStore.getState());
                    var resolved = objectUtil.resolveDefinitions(response, journey);
                    return resolved;
                }
            })
        };
    }
});
export var useLoadContentQuery = contentApi.useLoadContentQuery;
export default contentApi;
