var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { generatePath } from 'react-router-dom';
import * as paths from './paths';
import { getDraftQueryString, isAbsoluteUrl } from "./helper/url";
var IS_DEV = process.env.REACT_APP_JOURNEY_ENV === 'development';
export var getJourneyApi = function (journeyId) { return ({
    path: journeyId ? generatePath(paths.GET_JOURNEY_PATH, { journeyId: journeyId }) : paths.GET_JOURNEY_PATH
}); };
export var createBookingApi = function (_a) {
    var journeyId = _a.journeyId, ServiceRequest = _a.ServiceRequest, Customer = _a.Customer;
    return ({
        path: generatePath(paths.CREATE_BOOKING_PATH, { journeyId: journeyId }),
        method: 'POST',
        payload: { ServiceRequest: ServiceRequest, Customer: Customer }
    });
};
export var sendStylesApi = function (_a) {
    var resultId = _a.resultId, styles = _a.styles;
    return {
        path: generatePath(paths.GET_STYLES_PATH, { resultId: resultId }),
        method: 'PATCH',
        payload: __assign({}, styles)
    };
};
export var overwriteStylesApi = function (_a) {
    var resultId = _a.resultId, styles = _a.styles;
    return {
        path: generatePath(paths.GET_STYLES_PATH, { resultId: resultId }),
        method: 'PUT',
        payload: __assign({}, styles)
    };
};
export var deleteStylesApi = function (_a) {
    var resultId = _a.resultId, styles = _a.styles;
    return {
        path: generatePath(paths.GET_STYLES_PATH, { resultId: resultId }),
        method: 'DELETE',
        payload: __assign({}, styles)
    };
};
/**
 * In case of mulitple questionnaires the questionnaireHash will be necessary
 * In all other cases the journeyHash is sufficient
 */
export var sendAnswersApi = function (_a) {
    var journeyHash = _a.journeyHash, questionnaireHash = _a.questionnaireHash, Answers = _a.Answers, Customer = _a.Customer, meta = _a.meta;
    var hash = questionnaireHash ? { hash: questionnaireHash } : { journey_hash: journeyHash };
    var data = __assign({ Answers: Answers, Customer: Customer, meta: meta }, hash);
    return {
        path: paths.SEND_ANSWERS_PATH,
        method: 'POST',
        payload: { data: data }
    };
};
export var customEndpointApi = function (path, data) { return ({
    path: isAbsoluteUrl(path) ? path : generatePath(path, { resultId: data.alias || 'undefined' }),
    method: 'POST',
    payload: __assign({}, data)
}); };
export var getResultsApi = function (query) {
    if (query === void 0) { query = {}; }
    return ({
        path: IS_DEV ? paths.GET_RESULT_PATH_DEV : paths.GET_RESULT_PATH,
        query: __assign(__assign({}, query), getDraftQueryString())
    });
};
export var getImagesApi = function (data) { return ({
    path: paths.FETCH_GALLERY_IMAGES,
    method: 'POST',
    payload: __assign({}, data)
}); };
export var getProductsApi = function (data) { return ({
    path: paths.FETCH_PRODUCTS,
    method: 'POST',
    payload: __assign({}, data)
}); };
export var getResultLikeItems = function (_a) {
    var resultId = _a.resultId;
    return ({
        path: generatePath(paths.FETCH_RESULT_LIKES, { resultId: resultId }),
        method: 'GET'
    });
};
export var doLikeRequest = function (_a) {
    var resultId = _a.resultId, itemId = _a.itemId, itemType = _a.itemType, _b = _a.like, like = _b === void 0 ? true : _b;
    var LIKE_PATH = {
        "product": [paths.LIKE_PRODUCT, paths.UNLIKE_PRODUCT],
        "image": [paths.LIKE_IMAGE, paths.UNLIKE_IMAGE],
    };
    var currentPath = LIKE_PATH[itemType][like ? 0 : 1];
    return {
        path: generatePath(currentPath, { itemId: itemId }),
        method: like ? 'POST' : "DELETE",
        payload: {
            result_alias: resultId
        }
    };
};
export var getContentApi = function (contentType, contentParams) {
    return {
        path: paths.GET_CONTENT_PATH,
        query: __assign(__assign({ context: contentType }, getDraftQueryString()), contentParams)
    };
};
export var getContextComponentApi = function (journeyId, contextIds) {
    if (Array.isArray(contextIds))
        contextIds = contextIds.join(",");
    var generatedPath = generatePath(paths.GET_CONTEXT_COMPONENT_PATH, { journeyId: journeyId, contextIds: contextIds });
    var path = IS_DEV ? "".concat(paths.DEV_ROOT).concat(generatedPath) : generatedPath;
    return {
        path: path,
        query: getDraftQueryString()
    };
};
export function sendFeedbackApi(rating, alias, comment) {
    return {
        path: paths.SEND_FEEDBACK_PATH,
        method: 'POST',
        query: { alias: alias },
        payload: { comment: comment, rating: rating }
    };
}
/**
 *
 * @param close_to query param is used to pass a location (e.g. location, zip code)
 * locationType query param is used to pass an additional location type (e.g online, house, store)
 *
 */
export var getLocationsApi = function (_a) {
    var close_to = _a.close_to, journeyId = _a.journeyId, _b = _a.locationType, locationType = _b === void 0 ? null : _b, initialQuery = __rest(_a, ["close_to", "journeyId", "locationType"]);
    var query = initialQuery;
    if (close_to) {
        query.close_to = close_to;
    }
    query.journeyId = journeyId;
    if (locationType) {
        query.type = locationType;
    }
    return {
        path: paths.GET_LOCATIONS_PATH,
        query: query
    };
};
export var getLocationsMetaApi = function (_a) {
    var locationId = _a.locationId, key = _a.key;
    return {
        path: generatePath(paths.GET_LOCATIONS_META_PATH, { locationId: locationId }),
        query: { locationId: locationId, include: key }
    };
};
export var getVendorLocationApi = function (_a) {
    var projectId = _a.projectId;
    return {
        path: generatePath(paths.GET_VENDORLOCATION_PATH, { projectId: projectId })
    };
};
// TODO: DEFINE THIS PATH AND FUNCTION
export var getAvailabilityForReschedulingApi = function (_a) {
    var serviceRequestToken = _a.serviceRequestToken, timezone = _a.timezone, for_agent = _a.for_agent;
    return {
        path: generatePath(paths.GET_RESCHEDULING_AVAILABILITY_PATH, { serviceRequestToken: serviceRequestToken }),
        query: { tz: timezone, for_agent: for_agent }
    };
};
export var getAvailabilityApi = function (_a) {
    var projectId = _a.projectId, timezone = _a.timezone;
    return {
        path: "".concat(paths.GET_AVAILABILITY_PATH).concat(projectId),
        query: { tz: timezone }
    };
};
export var uploadImagesApi = function (payload) {
    return {
        path: paths.UPLOAD_IMAGES_PATH,
        method: 'POST',
        payload: payload,
        headers: {
            "Content-Type": "multipart/form-data"
        }
    };
};
export var getResultCalendarLinksApi = function (_a) {
    var hash = _a.hash;
    return {
        path: generatePath(paths.GET_RESULT_CALENDAR_LINKS_PATH, { hash: hash })
    };
};
