import { createSlice } from '@reduxjs/toolkit';
import { sendToHost } from 'conversional-iframe-communication';
import { getItem } from 'conversional-persistent-storage';
var initialState = {};
var trackingSlice = createSlice({
    name: 'tracking',
    initialState: initialState,
    reducers: {
        event: function (state, trackingAction) {
            var abTest = getItem('abTest');
            var _a = trackingAction.payload, category = _a.category, action = _a.action, label = _a.label;
            var eventData = { category: category, action: action, label: label, context: undefined };
            if (abTest)
                eventData['abTest'] = abTest;
            if (window.conversionalContext) {
                eventData.context = window.conversionalContext;
            }
            sendToHost.trackEvent(eventData);
        },
    },
});
export var event = trackingSlice.actions.event;
export default trackingSlice.reducer;
