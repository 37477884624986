import get from 'lodash/get';
import { JourneysSelectors, rtkApi } from "conversional-journey";
import { addFontPreload } from './domUtils';
import { flatten, mergeSets } from "./objectUtil";
export var preloadImages = function (images) {
    if (images === void 0) { images = []; }
    return images.forEach(function (image) { return preloadImage(image); });
};
/**
 * Although we don't add the image to DOM, the network request is made and the image is cached.
 * */
export var preloadImage = function (imageSrc) {
    var img = new Image();
    img.src = imageSrc;
};
export var preloadVideo = function (videoUrl, options) {
    if (options === void 0) { options = {}; }
    var video = document.createElement('video');
    if (options.id) {
        video.id = options.id;
    }
    video.src = videoUrl;
    // TypeScript does not have a style property on Element so use setAttribute instead
    video.setAttribute('style', 'position: fixed; top: -100vh; width: 0; height: 0; visibility: hidden;');
    document.body.appendChild(video);
};
var recognizeRJSFFields = function (ui) {
    var fields = [];
    Object.entries(ui).forEach(function (_a) {
        var key = _a[0], field = _a[1];
        if (get(field, 'ui:widget')) {
            fields.push(field);
        }
        else if (typeof field === "object") {
            fields = fields.concat(recognizeRJSFFields(field));
        }
    });
    return fields;
};
/**
 * Sometimes, builder configurations are not only the direct Children of components.
 * They are usually loaded with the function: createComponentsFromObject
 * This function recognizes those specific variations to perform preloading logic on.
 * */
var processOtherPossibleBuilderConfigurations = function (component, state, dispatch) {
    var contextComponents = new Set();
    switch (get(component, 'Content.type')) {
        case 'Form': {
            var uiFields = recognizeRJSFFields(get(component, 'Content.props.ui', {}));
            uiFields.forEach(function (uiField) {
                var tooltip = get(uiField, 'tooltip.content');
                if (tooltip) {
                    Object.entries(tooltip).forEach(function (_a) {
                        var key = _a[0], component = _a[1];
                        var componentPreloadingResult = processEachComponent(component, state, dispatch);
                        contextComponents = mergeSets(contextComponents, componentPreloadingResult.contextComponents);
                    });
                }
            });
        }
    }
    var flattened = flatten(component, { valueRegex: /contextComponent/ });
    var contextComponentConfigurations = get(flattened, 'processedResult', {});
    Object.keys(contextComponentConfigurations).forEach(function (key) {
        var componentKey = key.slice(0, key.length - "Content.format".length - 1);
        var componentPreloadingResult = processEachComponent(get(component, componentKey), state, dispatch);
        contextComponents = mergeSets(contextComponents, componentPreloadingResult.contextComponents);
    });
    return { contextComponents: contextComponents };
};
var processEachComponent = function (component, state, dispatch) {
    var contextComponents = new Set();
    if (get(component, 'Content.format', '') === "contextComponent") {
        var contextId = get(component, 'Content.type');
        contextComponents.add(contextId);
    }
    var childrenFromComponent = get(component, 'Children', []);
    Object.entries(childrenFromComponent).forEach(function (_a) {
        var _ = _a[0], component = _a[1];
        var componentPreloadingResult = processEachComponent(component, state, dispatch);
        contextComponents = mergeSets(contextComponents, componentPreloadingResult.contextComponents);
    });
    var otherBuilderConfigsPreloadingResult = processOtherPossibleBuilderConfigurations(component, state, dispatch);
    contextComponents = mergeSets(contextComponents, otherBuilderConfigsPreloadingResult.contextComponents);
    return { contextComponents: contextComponents };
};
var processTheme = function (theme) {
    var rawFont = get(theme, 'typo.fonts.byHand.src');
    if (rawFont) {
        var fontUrl = rawFont.split("url('")[1];
        if (fontUrl) {
            addFontPreload(fontUrl.split("')")[0]);
        }
    }
};
export var processConfigForPreloading = function (config, state, dispatch) {
    if (config.theme) {
        processTheme(config.theme);
    }
    if (config.entry) {
        var preloadingResult = processEachComponent(config.entry.root, state, dispatch);
        var contextComponents = preloadingResult.contextComponents || [];
        var contextIdsToPrefetch = Array.from(contextComponents);
        if (contextIdsToPrefetch.length === 0)
            return;
        var journeyId = JourneysSelectors.currentJourneyId(state);
        dispatch(
        // @ts-ignore
        rtkApi.endpoints.bulkFetchContextComponents.initiate({
            contextIds: contextIdsToPrefetch,
            journeyId: journeyId
        }));
    }
};
export var preloadResultConfig = function (resultConfig, state, dispatch) {
    processConfigForPreloading({
        theme: get(resultConfig, 'config.theme', {}),
        entry: get(resultConfig, 'content', {})
    }, state, dispatch);
};
export var preloadContextComponent = function (contextComponent, state, dispatch) {
    processConfigForPreloading({
        entry: {
            root: contextComponent
        }
    }, state, dispatch);
};
